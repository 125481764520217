import React, { useState } from "react";
import {
  TextField,
  DialogActions,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Modal,
  Backdrop,
  Box,
  Autocomplete,
  Typography,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { showNotification } from "../../services/helper";
import { useQueryClient } from "@tanstack/react-query";
import { queryConfigs } from "../../hooks/config";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Medicine {
  id: number;
  name: string;
  description?: string;
  prescribed_by?: number;
  timing?: string;
  type?: string;
  dose?: string;
}

type AddEmpProps = {
  open: boolean;
  onClose: () => void;
};

type DoctorRow = {
  id: number;
  name: string;
};

const AddTreatment = ({ open, onClose }: AddEmpProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(
    null
  );
  const [formattedDate, setFormattedDate] = useState<string | undefined>(
    undefined
  );

  const params = useParams();
  const pTId = parseInt(params.id || "0");

  const { queryKey: medicines, queryFn: medFunc } = queryConfigs.getMedicines;
  const { data: medicineData } = useGetQuery({
    key: medicines,
    func: medFunc,
    params: { offset: 0, limit: 100 },
  });

  const { queryKey: doctors, queryFn: doctorFunc } = queryConfigs.getDoctors;
  const { data: doctorData } = useGetQuery({
    key: doctors,
    func: doctorFunc,
  });

  const queryClient = useQueryClient();
  const { queryFn, queryKey } = queryConfigs.useAddTreatment;
  const { mutate } = useMutationQuery({
    key: queryKey,
    func: queryFn,
    onSuccess: () => {
      showNotification("success", "Treatment Records added successfully");
      onClose();
    },
  });

  const handleClearForm = () => {
    setSelectedMedicines([]);
    setRemarks("");
    setSelectedDate(null);
  };

  const handleSubmit = () => {
    if (selectedMedicines.length === 0) {
      showNotification("error", "Please select at least one medicine.");
      return;
    }

    const filteredMedicines = selectedMedicines?.map(
      ({ description, ...rest }) => ({
        medicine: rest.id,
        name: rest.name,
        prescribed_by: rest.prescribed_by || null,
        type: rest.type || null,
        timing: rest.timing || null,
        dose: rest.dose || null,
      })
    );

    if (
      !formattedDate ||
      dayjs(formattedDate, "YYYY-MM-DD HH:mm:ss", true).isValid() === false
    ) {
      showNotification("error", "Please provide a valid administered date.");
      return;
    }

    const updatedForm = {
      patient: parseInt(params.id || "0"),
      remarks: remarks.trim(),
      medicines: filteredMedicines,
      administered: formattedDate,
    };

    mutate(updatedForm);
    onClose();
    handleClearForm();
  };

  const handleCancel = () => {
    onClose();
    handleClearForm();
  };

  const [selectedMedicines, setSelectedMedicines] = useState<Medicine[]>([]);
  const [remarks, setRemarks] = useState<string>("");

  const handleRemarksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemarks(event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: Medicine[]) => {
    setSelectedMedicines(newValue);
  };

  const handlePrescriberChange = (id: number, value: number) => {
    setSelectedMedicines((prev) =>
      prev?.map((medicine) =>
        medicine.id === id ? { ...medicine, prescribed_by: value } : medicine
      )
    );
  };

  const handleTypeChange = (id: number, value: string) => {
    setSelectedMedicines((prev) =>
      prev?.map((medicine) =>
        medicine.id === id ? { ...medicine, type: value } : medicine
      )
    );
  };

  const handleDoseChange = (id: number, value: string) => {
    setSelectedMedicines((prev) =>
      prev?.map((medicine) =>
        medicine.id === id ? { ...medicine, dose: value } : medicine
      )
    );
  };

  const handleTimingChange = (id: number, value: string) => {
    setSelectedMedicines((prev) =>
      prev?.map((medicine) =>
        medicine.id === id ? { ...medicine, timing: value } : medicine
      )
    );
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      const formatted = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
      setFormattedDate(formatted);
    } else {
      setFormattedDate(undefined);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "600px",
    height: selectedMedicines.length === 0 ? "56%" : "90%",
    bgcolor: "background.paper",
    backdropFilter: "blur(3px)",
    borderRadius: 2,
    overflowY: "auto",
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <div className="bg-[#1C1C1C] sticky top-0 z-10 flex justify-between items-center px-4">
          <DialogTitle
            sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
          >
            Add Treatment
          </DialogTitle>
          <IconButton onClick={onClose} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <Box sx={{ padding: 2 }}>
          <td
            style={{ position: "relative", zIndex: "999" }}
            className="w-full z-50"
          >
            <DatePicker
              required
              selected={selectedDate ? dayjs(selectedDate).toDate() : null}
              onChange={handleDateChange}
              dateFormat="dd, MMMM, yyyy"
              placeholderText="Medicine Recorded Date"
              maxDate={dayjs().toDate()}
              className="px-6 py-4 w-[100%] h-12 border border-gray-200 bg-white rounded-3xl placeholder-gray-500"
              popperPlacement="bottom-start"
              wrapperClassName="w-full"
            />
          </td>
          <Autocomplete
            multiple
            id="medicines-autocomplete"
            options={medicineData?.list || []}
            getOptionLabel={(option) => option.name}
            value={selectedMedicines}
            onChange={handleChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Select Medicines"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontFamily: "Poppins",
                    fontSize: "13px",
                  },
                }}
              />
            )}
            fullWidth
            sx={{
              width: "100%",
              marginTop: 2,
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
                borderRadius: "1.5rem",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
                textTransform: "capitalize",
              },
              "& .MuiSelect-icon": {
                color: "gray",
              },
            }}
          />
          {selectedMedicines.length > 0 && (
            <Box sx={{ marginTop: 2, overflowY: "auto" }}>
              {selectedMedicines?.map((medicine) => (
                <Box key={medicine.id} sx={{ marginBottom: 2, padding: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      textTransform: "capitalize",
                    }}
                  >
                    {medicine.name}
                  </Typography>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: 1 }}
                  >
                    <InputLabel
                      style={{
                        color: "gray",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13px",
                      }}
                    >
                      Prescriber
                    </InputLabel>
                    <Select
                      value={medicine.prescribed_by || ""}
                      onChange={(e) =>
                        handlePrescriberChange(
                          medicine.id,
                          e.target.value as number
                        )
                      }
                      label="Prescriber"
                      sx={{
                        width: "100%",
                        marginBottom: 1,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "1.5rem",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "inherit",
                          borderRadius: "1.5rem",
                        },
                        "& .MuiInputBase-input": {
                          height: "1rem",
                          padding: "1rem 1rem",
                          textTransform: "capitalize",
                        },
                        "& .MuiSelect-icon": {
                          color: "gray",
                        },
                      }}
                    >
                      {doctorData?.list?.map((doctor: DoctorRow) => (
                        <MenuItem key={doctor.id} value={doctor.id}>
                          {doctor.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    label="Type"
                    value={medicine.type || ""}
                    onChange={(e) =>
                      handleTypeChange(medicine.id, e.target.value)
                    }
                    InputLabelProps={{
                      sx: {
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13px",
                      },
                    }}
                    sx={{
                      width: "100%",
                      marginBottom: 1,
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "1.5rem",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "inherit",
                        borderRadius: "1.5rem",
                      },
                      "& .MuiInputBase-input": {
                        height: "1rem",
                        padding: "1rem 1rem",
                        textTransform: "capitalize",
                      },
                      "& .MuiSelect-icon": {
                        color: "gray",
                      },
                    }}
                  />
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    label="Dose"
                    value={medicine.dose || ""}
                    onChange={(e) =>
                      handleDoseChange(medicine.id, e.target.value)
                    }
                    InputLabelProps={{
                      sx: {
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13px",
                      },
                    }}
                    sx={{
                      width: "100%",
                      marginBottom: 1,
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "1.5rem",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "inherit",
                        borderRadius: "1.5rem",
                      },
                      "& .MuiInputBase-input": {
                        height: "1rem",
                        padding: "1rem 1rem",
                        textTransform: "capitalize",
                      },
                      "& .MuiSelect-icon": {
                        color: "gray",
                        fontFamily: "Popins, sans-serif",
                      },
                    }}
                  />
                  <FormControl
                    required
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: 1 }}
                  >
                    <InputLabel
                      sx={{
                        color: "gray",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13px",
                      }}
                    >
                      Timing
                    </InputLabel>
                    <Select
                      value={medicine.timing || ""}
                      onChange={(e) =>
                        handleTimingChange(
                          medicine.id,
                          e.target.value as string
                        )
                      }
                      label="Timing"
                      sx={{
                        width: "100%",
                        marginBottom: 1,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "1.5rem",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "inherit",
                          borderRadius: "1.5rem",
                        },
                        "& .MuiInputBase-input": {
                          height: "1rem",
                          padding: "1rem 1rem",
                          textTransform: "capitalize",
                        },
                        "& .MuiSelect-icon": {
                          color: "gray",
                          fontFamily: "Popins, sans-serif",
                        },
                      }}
                    >
                      <MenuItem value="BD">BD</MenuItem>
                      <MenuItem value="HS">HS</MenuItem>
                      <MenuItem value="OD">OD</MenuItem>
                      <MenuItem value="SOS">SOS</MenuItem>
                      <MenuItem value="TIDOR">TIDOR</MenuItem>
                      <MenuItem value="TDS">TDS</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              ))}
            </Box>
          )}
          <TextField
            label="Remarks"
            variant="outlined"
            multiline
            maxRows={2}
            value={remarks}
            onChange={handleRemarksChange}
            fullWidth
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            sx={{
              width: "100%",
              marginTop: 2,
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
                textTransform: "capitalize",
              },
            }}
          />
          <div className="flex justify-center my-4 items-center">
            <DialogActions>
              <div className="mr-12">
                <button
                  onClick={handleSubmit}
                  className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
                >
                  <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                  <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                  <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                    Add
                  </span>
                  <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
                </button>
              </div>
              <div className="ml-12">
                <button
                  onClick={handleCancel}
                  className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
                >
                  <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                  <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                  <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                    Cancel
                  </span>
                  <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
                </button>
              </div>
            </DialogActions>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddTreatment;
