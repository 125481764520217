import React, { ChangeEvent, useRef, useState } from "react";
import { Modal } from "@mui/material";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type medicines = {
  id: number;
  name: string;
};

type PtRow = {
  admission_id: number;
  id: number;
  created_on: string;
  name: string;
  patient_name: string;
  mobile: string;
  age: string;
  diagnosis: string;
  marital_status: string;
  address: string;
  district: string;
  pincode: string;
  emergency_contact: string;
  gender: string;
  remark: string;
  admission_charge: string;
  admission_fee?: number;
  admission_date?: string;
  discharge_date?: string;
  referred_by?: number;
  is_discharged?: boolean;
  medicines?: medicines[];
};

type DischargeProps = {
  open: boolean;
  onClose: () => void;

  admission?: PtRow | null | undefined;
};

interface Organisation {
  name: string;
  moto: string;
  address: string;
}

interface DischargeFormData {
  organisation: Organisation;
  name: string;
  address: string;
  admission_date: string;
  diagnosis: string;
  medicines: string;
  age: number;
  recommendation?: string;
  guardianFamily?: string;
  guardianName?: string;
  guardianAddress?: string;
  guardianRelationship?: string;
  guardianSignature?: string;
  discharged_on?: string;
}

interface DischargeFormProps {
  data: DischargeFormData;
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 600,
  bgcolor: "background.paper",
  backdropFilter: "blur(3px)",
  borderRadius: 2,
  overflowY: "auto",
};
const DischargeSummaryModal: React.FC<DischargeProps> = ({
  open,
  onClose,
  admission,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    relationship: "",
  });
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <>
        <button className="generate-btn" onClick={() => reactToPrintFn()}>
          Print
        </button>
        <div
          className="max-w-[21cm] mx-auto p-4 bg-white overflow-auto max-h-screen"
          ref={contentRef}
        >
          <div className="h-[2.8cm]"></div>
          <h2 className="text-center font-bold text-lg mt-6">
            DISCHARGE CERTIFICATE
          </h2>

          <div className="mt-4 flex flex-col gap-2">
            <section className="flex justify-between px-6">
              <div>
                <div className="flex items-center">
                  <p className="text-xs mr-2">Patient Name: </p>
                  <p className="text-xs">{admission?.patient_name}</p>
                </div>
                <div className="flex items-center">
                  <p className="text-xs mr-2">Age: </p>
                  <p className="text-xs">{admission?.age}</p>
                </div>
                <div className="flex items-center">
                  <p className="text-xs mr-2">Mobile: </p>
                  <p className="text-xs">{admission?.mobile}</p>
                </div>
                <div className="flex items-center">
                  <p className="text-xs mr-2">Emergency Contact: </p>
                  <p className="text-xs">{admission?.emergency_contact}</p>
                </div>
                <div className="flex items-center">
                  <p className="text-xs mr-2">Diagnosis: </p>
                  <p className="text-xs">{admission?.diagnosis}</p>
                </div>
              </div>
              <div className="w-[40%]">
                <div className="flex">
                  <p className="text-xs mr-2">Address: </p>
                  <p className="text-xs">
                    {admission?.address}, {admission?.district},{" "}
                    {admission?.pincode}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-xs mr-2">Admitted On: </p>
                  <p className="text-xs">
                    {dayjs(admission?.admission_date).format("DD-MMM-YYYY")}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-xs mr-2">Discharged On: </p>
                  <p className="text-xs">
                    {dayjs(admission?.discharge_date).format("DD-MMM-YYYY")}
                  </p>
                </div>
              </div>
            </section>

            <div className="flex flex-col gap-4 px-5">
              <div>
                <label className="text-xs">Brief Clinical Note</label>
                <textarea rows={4} className="w-full p-2 h-12 resize-none" />
              </div>
              <div>
                <label className="text-xs">
                  Results-Cure/Recovered/Otherwise
                </label>
                <textarea rows={2} className="w-full p-2 h-12 resize-none" />
              </div>

              <div>
                <label className="text-xs">Medication</label>
                <textarea rows={2} className="w-full p-2 h-12 resize-none" />
              </div>
              <div>
                <label className="text-xs">Recommendation</label>
                <textarea rows={2} className="w-full p-2 h-12 resize-none" />
              </div>
            </div>

            <section className="px-5">
              <p className="text-sm font-semibold">
                Guardian/Family with whom the patient is discharged
              </p>
              <div className="flex flex-col">
                <div className="flex items-center space-x-4">
                  <p className="text-xs">Name: </p>
                  <input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="p-2 "
                  />
                </div>
                <div className="flex items-center space-x-4">
                  <p className="text-xs">Address: </p>
                  <input
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className="p-2 "
                  />
                </div>
                <div className="flex items-center space-x-4">
                  <p className="text-xs">Relationship: </p>
                  <input
                    name="relationship"
                    value={formData.relationship}
                    onChange={handleChange}
                    className="p-2 "
                  />
                </div>
              </div>
              <div className="mt-2">
                <p className="text-sm">Signature:</p>
              </div>
            </section>
          </div>

          <div className="mt-4 flex justify-end px-5">
            <p className="text-xs font-bold">
              Signature of the Project Director
            </p>
          </div>
          <div className="h-[2cm]"></div>
        </div>
      </>
    </Modal>
  );
};

export default DischargeSummaryModal;
