import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Dashboard from "./components/dashboard/Dashboard";
import AdminPage from "./pages/AdminPage";
import { getItem } from "./services/helper";
import { setAuthHeader } from "./services/api";
import Login from "./pages/Login";
import { useDispatch, useSelector } from "react-redux";
import { setOrganisation, setToken, setUser } from "./redux/features/authSlice";
import { RootState } from "./redux/store";

const queryClient = new QueryClient();

function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const isSidebarCollapsed = false;
  const location = useLocation();

  const organisation = useSelector(
    (state: RootState) => state.auth.organisation
  );
  const user = useSelector((state: RootState) => state.auth.user);
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    const storedToken = getItem("token");
    const storedUserData = getItem("user");
    const storedOrgData = getItem("organisation");

    dispatch(setOrganisation(storedOrgData));
    dispatch(setUser(storedUserData));
    dispatch(setToken(storedToken));

    if (storedToken && storedToken.length > 0 && storedToken !== "") {
      setAuthHeader(storedToken);

      // Redirect if already on login page and token is present
      if (location.pathname === "/") {
        if (storedUserData.role === 0) {
          navigate("/admin");
        } else {
          navigate("/admin/in-patient");
        }
      }
    } else {
      // If no token, redirect to login page
      navigate("/");
    }

    setIsLoading(false);
  }, [location.pathname, navigate]);

  if (isLoading) return <></>;
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admin/*" element={<AdminPage />} />
        <Route
          path="/admin/*"
          element={<Dashboard isSidebarCollapsed={isSidebarCollapsed} />}
        />
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
