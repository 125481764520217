import React, { useState } from "react";
import {
  TextField,
  DialogActions,
  DialogTitle,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Modal,
  Backdrop,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { queryConfigs } from "../../hooks/config";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { showNotification } from "../../services/helper";
import { useQueryClient } from "@tanstack/react-query";

type AddEmpProps = {
  open: boolean;
  onClose: () => void;
};

type DesignationMenu = {
  id: number;
  name: string;
  organisation: number;
  created_on: string;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit",
  height: 520,
  bgcolor: "background.paper",
  backdropFilter: "blur(3px)",
  borderRadius: 2,
  overflowY: "auto",
};
const AddEmployee = ({ open, onClose }: AddEmpProps) => {
  const { queryKey: designations, queryFn: designationFunc } =
    queryConfigs.getDesignations;
  const { data: designationdata, isLoading: isDesignationLoading } =
    useGetQuery({
      key: designations,
      func: designationFunc,
    });

  const [formValues, setFormValues] = useState({
    name: "",
    mobile: "",
    address: "",
    email: "",
    image: "",
    designation: null,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };
  const queryClient = useQueryClient();
  const { queryFn, queryKey } = queryConfigs.useAddEmployee;
  const { mutate } = useMutationQuery({
    key: queryKey,
    func: queryFn,
    onSuccess: () => {
      showNotification("success", "Employee added successfully");
      onClose();
    },
  });

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleClearForm = () => {
    setFormValues({
      name: "",
      address: "",
      mobile: "",
      email: "",
      image: "",
      designation: null,
    });
  };

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const validateForm = () => {
    const { name, mobile, email, designation } = formValues;
    const errors = [];

    if (!name || name.trim() === "") {
      errors.push("Name is required");
    }
    if (!mobile || mobile.trim() === "" || mobile === "0") {
      errors.push("Mobile is required");
    }
    if (!email || email.trim() === "") {
      errors.push("Email is required");
    }
    if (!designation) {
      errors.push("Designation is required");
    }

    if (errors.length === 4) {
      return "All fields are missing";
    }

    return errors.length > 0 ? errors.join(", ") : null;
  };
  const handleSubmit = () => {
    const validationError = validateForm();
    if (validationError) {
      setErrorMessage(validationError);
      showNotification("error", validationError);
      return;
    }
    mutate(formValues);

    onClose();
    handleClearForm();
  };

  const handleCancel = () => {
    onClose();
    handleClearForm();
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <div className="bg-[#1C1C1C] sticky top-0 z-10 flex justify-between items-center px-4">
          <DialogTitle
            sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
          >
            Add New Employee
          </DialogTitle>
          <IconButton onClick={onClose} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <section className="space-y-5 p-5">
          <TextField
            required
            name="name"
            label="Name"
            value={formValues.name}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            InputProps={{
              classes: {
                root: "text-black",
                input: "h-8 py-0",
              },
            }}
            inputProps={{
              className: "text-black",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
              },
            }}
            className="rounded-3xl bg-white"
          />
          <TextField
            required
            name="address"
            label="Address"
            value={formValues.address}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            InputProps={{
              classes: {
                root: "text-black",
                input: "h-8 py-0",
              },
            }}
            inputProps={{
              className: "text-black",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
              },
            }}
            className="rounded-3xl bg-white"
          />
          <TextField
            required
            name="mobile"
            label="Mobile"
            value={formValues.mobile}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            InputProps={{
              inputProps: {
                maxLength: 10,
                pattern: "[0-9]*",
              },
              classes: {
                root: "text-black",
                input: "h-10 py-0",
              },
            }}
            inputProps={{
              className: "text-black font-Poppins",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
              },
            }}
          />
          <TextField
            required
            name="email"
            label="Email"
            value={formValues.email}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            InputProps={{
              classes: {
                root: "text-black",
                input: "h-10 py-0",
              },
            }}
            inputProps={{
              className: "text-black",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
              },
            }}
            className="rounded-3xl bg-white"
          />
          <FormControl fullWidth margin="dense" variant="outlined" required>
            <InputLabel
              style={{
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              }}
            >
              Designation
            </InputLabel>
            <Select
              name="designation"
              value={formValues.designation || ""}
              onChange={handleSelectChange}
              label="Designation"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                  borderRadius: "1.5rem",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                },
                "& .MuiSelect-icon": {
                  color: "gray",
                },
              }}
              className="rounded-3xl bg-white text-black"
            >
              {designationdata?.list?.map((designation: DesignationMenu) => (
                <MenuItem key={designation.id} value={designation.id}>
                  {designation.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </section>
        <div className="flex justify-center mb-4 items-center">
          <DialogActions>
            <div className="mr-12">
              <button
                onClick={handleSubmit}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Add
                </span>
                <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
              </button>
            </div>
            <div className="ml-12">
              <button
                onClick={handleCancel}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Cancel
                </span>
                <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
              </button>
            </div>
          </DialogActions>
        </div>
      </Box>
    </Modal>
  );
};

export default AddEmployee;
