import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Backdrop,
  Modal,
  Box,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery } from "../../hooks/queryHook";
import dayjs from "dayjs";
import GenericPdfDownloader from "../GenericPdfDownloader";
import { IoCloseCircleOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getEndingTimeOfDate,
  getStartingTimeOfDate,
} from "../../services/helper";
import { Emp } from "../../types/common";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type Medicine = {
  id: number;
  medicine: number;
  treatment: number;
  created_on: string;
  name?: string;
  prescribed_by: number | null;
  type?: string;
  timing?: string;
  dose?: string;
};

type Treatment = {
  id: number;
  patient: number;
  remarks: string;
  created_on: string;
  medicines: Medicine[];
};

type TreatmentResponse = {
  count: number;
  list: Treatment[];
  success: boolean;
};

type DoctorRow = {
  id: number;
  created_on: string;
  name: string;
};

interface TreatmentTableProps {
  treatments?: TreatmentResponse;
  open: boolean;
  pTId: number;
  onClose: () => void;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "90%",
  bgcolor: "background.paper",
  backdropFilter: "blur(3px)",
  borderRadius: 2,
  overflowY: "auto",
};
interface Record {
  id: number;
  created_on: string;
  patient: number;
  blood_high: string;
  blood_low: string;
  pulse_rate: string;
  bio_func: string;
  spo: string;
  weight: number;
  in_charge: number;
}
const getMedicineName = (medicineId: number, medicineList: Medicine[]) => {
  return medicineList.find((med) => med.id === medicineId)?.name || "";
};

const getDoctorName = (doctorId: number | null, doctorList: DoctorRow[]) => {
  return doctorList.find((doc) => doc.id === doctorId)?.name || "";
};

const VItalDownload: React.FC<TreatmentTableProps> = ({
  open,
  onClose,
  pTId,
}: TreatmentTableProps) => {
  const [currentDate, setCurrentDate] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartStartDate, setChartStartDate] = useState<string | null>(null);
  const [chartEndDate, setChartEndDate] = useState<string | null>(null);
  useEffect(() => {
    const today = dayjs().format("YYYY-MM-DD");
    setCurrentDate(today);
  }, []);
  useEffect(() => {
    if (!pTId || pTId <= 0) {
      onClose();
    }
  }, [pTId]);

  const { queryKey, queryFn } = queryConfigs.getVitalByPatient;
  const { data, refetch, isLoading } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      patient: pTId,
      startDate: chartStartDate,
      endDate: chartEndDate,
    },
    isEnabled: chartStartDate !== null && chartEndDate !== null,
  });

  const { queryKey: ptkey, queryFn: getPtByID } = queryConfigs.getPatientById;

  const { data: ptData } = useGetQuery({
    key: ptkey,
    func: getPtByID,
    params: {
      id: pTId,
    },
    isEnabled: pTId != null,
  });
  const handleChartDateRange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    const formattedStart = start ? getStartingTimeOfDate(dayjs(start)) : null;
    const formattedEnd = end ? getEndingTimeOfDate(dayjs(end)) : null;

    setChartStartDate(formattedStart);
    setChartEndDate(formattedEnd);
  };
  const { queryKey: employees, queryFn: employeesFunc } =
    queryConfigs.useGetEmployees;
  const { data: employeeData, isLoading: isEmployeeLoaiding } = useGetQuery({
    key: employees,
    func: employeesFunc,
  });

  const handleClose = () => {
    onClose();
    setChartStartDate(null);
    setChartEndDate(null);
  };
  const handleClear = () => {
    setChartStartDate(null);
    setChartEndDate(null);
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <div className="bg-[#1C1C1C] sticky top-0 z-30 flex justify-between items-center px-4">
          <DialogTitle
            sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
          >
            Download Vital Charts
          </DialogTitle>
          <IconButton onClick={handleClose} sx={{ color: "white" }}>
            <IoCloseCircleOutline />
          </IconButton>
        </div>
        <div className="w-[210mm] p-[4mm] mx-auto">
          <div className=" flex items-center space-x-5 ">
            <DatePicker
              selected={chartStartDate ? dayjs(chartStartDate).toDate() : null}
              onChange={handleChartDateRange}
              startDate={
                chartStartDate ? dayjs(chartStartDate).toDate() : undefined
              }
              endDate={chartEndDate ? dayjs(chartEndDate).toDate() : undefined}
              selectsRange
              dateFormat="yyyy-MM-dd"
              placeholderText="Select Date Range"
              className="w-[300px] border rounded  py-2 px-4"
            />
            <button onClick={handleClear} className="clear-btn">
              Clear
            </button>
            <GenericPdfDownloader
              rootElementId1="vitalTable"
              downloadFileName={`VitalChart-${currentDate}`}
            />
          </div>
        </div>
        <section id="vitalTable" className="w-[210mm] p-[4mm] mx-auto">
          <div className="mt-4 w-full ">
            <TableContainer component={Paper}>
              <div className=" text-center text-2xl font-semi">
                VITAL CHARTS
              </div>
              <section className="flex justify-between px-5 mb-5">
                <section className="px-4 text-sm">
                  <div className="flex space-x-2 items-center">
                    <p>Name:</p>
                    <p className="capitalize">{ptData?.data?.name}</p>
                  </div>

                  <div className="flex space-x-2 items-center">
                    <p>Gender:</p>
                    <p className="capitalize">{ptData?.data?.gender}</p>
                  </div>
                </section>
                <section className="text-sm">
                  <div className="flex space-x-2 items-center">
                    <p>Mobile:</p>
                    <p className="capitalize">{ptData?.data?.mobile}</p>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <p>Emergency Contact:</p>
                    <p className="capitalize">
                      {ptData?.data?.emergency_contact}
                    </p>
                  </div>
                </section>
              </section>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ fontSize: "12px" }}>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                      Date
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                      Blood Pressure (High/Low)
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                      Pulse Rate
                      <p style={{ margin: 0, fontSize: "12px" }}>(per min)</p>
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                      Bio Function
                      <p style={{ margin: 0, fontSize: "12px" }}>
                        sleep/appetite/bowel & bladder / LMP
                      </p>
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                      SPO
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                      Weight
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                      In Charge
                    </TableCell>
                  </TableRow>
                </TableHead>

                {data && (
                  <TableBody>
                    {data?.list?.map((record: Record) => (
                      <TableRow key={record.id} sx={{ fontSize: "12px" }}>
                        <TableCell
                          align="center"
                          sx={{ width: "100px", fontSize: "12px" }}
                        >
                          {dayjs(record.created_on).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "12px" }}>
                          {record.blood_high}/{record.blood_low}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "100px", fontSize: "12px" }}
                        >
                          {record.pulse_rate}
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "12px" }}>
                          {record.bio_func}
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "12px" }}>
                          {record.spo}
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "12px" }}>
                          {record.weight} kg
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: "12px" }}>
                          {employeeData?.list.find(
                            (d: Emp) => d.id === record.in_charge
                          )?.name ?? ""}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </section>
      </Box>
    </Modal>
  );
};

export default VItalDownload;
