import React, { useEffect, useState } from "react";
import CommonTable from "../CommonTable";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { queryConfigs } from "../../hooks/config";
import AddUser from "./AddUser";
import UpdatePassword from "./UpdatePassword";
import dayjs from "dayjs";
import Edituser from "./Edituser";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { showNotification } from "../../services/helper";
import { IoCloseCircleOutline } from "react-icons/io5";
import { CiCreditCard2 } from "react-icons/ci";
import { SiSecurityscorecard } from "react-icons/si";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type UsersProps = {
  pageTitle: string;
};

type Header = {
  name: string;
};

const userHeaders: Header[] = [
  { name: "ID" },
  { name: "Name" },
  { name: "Role" },
  { name: "Mobile" },
  { name: "Email" },
  { name: "Created on" },
  { name: "Action" },
];
type UserSearch = {
  name: string | null;
  mobile: number | null;
  id: number | null;
  email: string | null;
};

const Users: React.FC<UsersProps> = ({ pageTitle }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const [userSearch, setUserSearch] = useState<UserSearch>({
    name: null,
    mobile: null,
    id: null,
    email: null,
  });
  const [searchUser, setSearchUser] = useState<UserSearch>(userSearch);
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [viewOpen, setViewOpen] = useState(false);

  // Effect Hooks
  useEffect(() => {
    setCurrentPage(Math.floor(pagination.offset / pagination.limit) + 1);
  }, [pagination.offset, pagination.limit]);

  // Handlers for User Interaction

  const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserSearch((prevState) => ({
      ...prevState,
      [name]:
        name === "id" || name === "mobile"
          ? value
            ? Number(value)
            : null
          : value || null,
    }));
  };

  const handleSearch = () => {
    setSearchUser(userSearch);
  };

  const handleSearchClear = () => {
    setUserSearch({
      name: null,
      mobile: null,
      id: null,
      email: null,
    });
    setSearchUser({
      name: null,
      mobile: null,
      id: null,
      email: null,
    });
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setCurrentPage(page + 1);
    setPagination((prev) => ({
      ...prev,
      offset: page * pagination.limit,
    }));
  };
  const [toDelete, setToDelete] = useState<number | null>();
  const [deleteOpen, setDeleteOpen] = useState(false);
  // Query and Data Fetching
  const limit = pagination.limit;
  const { queryKey, queryFn } = queryConfigs.getUsers;
  const userHeaderNames: string[] = userHeaders.map((header) => header.name);
  const { data, refetch, isLoading } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      offset: (currentPage - 1) * pagination.limit,
      limit: pagination.limit,
      name: searchUser.name ?? "",
      id: searchUser.id ?? null,
      mobile: searchUser.mobile ?? null,
      email: searchUser.email ?? null,
    },
  });

  // Render Logic
  const handleAddClick = () => {
    setIsAddUserOpen(true);
  };

  const handleEditClick = (user: any) => {
    setViewOpen(true);
    if (user.id > 0) {
      setEditValues({ ...user });
    }
  };
  const [openPwd, setOpenPwd] = useState(false);
  const handleUpdatePassword = (user: any) => {
    setOpenPwd(true);
    if (user.id > 0) {
      setEditValues({ ...user });
    }
  };
  const { mutationFn: deleteUser, invalidateKey: deleteKey } =
    queryConfigs.deleteUser;
  const { mutate } = useMutationQuery({
    key: deleteKey,
    func: deleteUser,
    onSuccess: () => {
      showNotification("success", "User deleted successfully");
      handleDeleteClose();
    },
  });
  const handleDeleteOpen = (id: number) => {
    setDeleteOpen(true);
    setToDelete(id);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setToDelete(null);
  };

  const handleDelete = () => {
    mutate(toDelete);
  };
  const handleRefetch = () => {
    handleSearchClear();
    refetch();
  };

  return (
    <div className="w-full overflow-hidden mx-auto relative h-screen">
      <CommonTable
        tableHeaders={userHeaderNames}
        totalRows={data?.totalCount || 0}
        refetchIcon={true}
        onRefetchClick={handleRefetch}
        colSpan={userHeaders.length}
        pageTitle={pageTitle}
        onAddClick={handleAddClick}
        marginTop={16}
        showInchargeFilter={false}
        showDesignationFilter={false}
        showIdFilter={false}
        children2={
          <>
            <div className="flex items-center space-x-5 w-full">
              <TextField
                label="Search By ID"
                variant="outlined"
                name="id"
                value={userSearch.id || ""}
                onChange={handleUserChange}
                size="small"
                sx={{
                  width: "20%",
                  "& .MuiInputLabel-root": {
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
              />
              <TextField
                label="Search By Name"
                variant="outlined"
                name="name"
                value={userSearch.name || ""}
                onChange={handleUserChange}
                size="small"
                sx={{
                  width: "20%",
                  "& .MuiInputLabel-root": {
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
              />
              <TextField
                label="Search By Mobile"
                variant="outlined"
                name="mobile"
                value={userSearch.mobile || ""}
                onChange={handleUserChange}
                size="small"
                sx={{
                  width: "20%",
                  "& .MuiInputLabel-root": {
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
              />

              <TextField
                label="Search By Email"
                variant="outlined"
                name="email"
                value={userSearch.email || ""}
                onChange={handleUserChange}
                size="small"
                sx={{
                  width: "20%",
                  "& .MuiInputLabel-root": {
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
              />

              <button onClick={handleSearch} className="search-btn">
                Search
              </button>
              <button onClick={handleSearchClear} className="clear-btn">
                Clear
              </button>
            </div>
          </>
        }
        children3={
          <TablePagination
            component="div"
            count={data?.totalCount || 0}
            page={currentPage - 1}
            rowsPerPage={limit}
            onPageChange={handlePageChange}
            onRowsPerPageChange={(event) => {
              const newLimit = parseInt(event.target.value, 10);
              setPagination((prev) => ({
                ...prev,
                limit: newLimit,
                offset: 0,
              }));
              setCurrentPage(1);
            }}
            sx={{ color: "#38a3a5", font: "Poppins, sans-serif" }}
          />
        }
      >
        {/* {data?.result?.users.map((user: any) => ( */}
        {/* <TableRow key={user.id}> */}
        {data?.users?.map((user: any) => (
          <TableRow key={user.id}>
            <TableCell align="center" className="capitalize">
              {user.id}
            </TableCell>
            <TableCell align="center" className="capitalize">
              {user.name}
            </TableCell>
            <TableCell align="center" className="capitalize">
              {user.role === 1 && "Staff"}
              {user.role === 0 && "Admin"}
            </TableCell>
            <TableCell align="center" className="capitalize">
              {user.mobile}
            </TableCell>
            <TableCell align="center" className="capitalize">
              {user.email}
            </TableCell>

            <TableCell align="center" className="capitalize">
              {dayjs(user.created_on).format("DD-MM-YYYY")}
            </TableCell>
            <TableCell align="center" className="space-x-3">
              <Tooltip title="Edit" arrow>
                <button
                  onClick={() => handleEditClick(user)}
                  className="edit-btn"
                >
                  <FaEdit />
                </button>
              </Tooltip>
              <Tooltip title="Update Password" arrow>
                <button
                  onClick={() => handleUpdatePassword(user)}
                  className="edit-btn-success"
                >
                  <SiSecurityscorecard />
                </button>
              </Tooltip>

              <Tooltip title="Delete" arrow>
                <button
                  onClick={() => handleDeleteOpen(user.id)}
                  className="del-btn"
                >
                  <MdDeleteForever />
                </button>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
        {/* ))} */}
      </CommonTable>
      <AddUser open={isAddUserOpen} onClose={() => setIsAddUserOpen(false)} />
      <Edituser
        open={viewOpen}
        onClose={() => setViewOpen(false)}
        userData={editValues}
      />
      <UpdatePassword
        open={openPwd}
        onClose={() => setOpenPwd(false)}
        userData={editValues}
      />

      <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <div className=" flex justify-between items-center px-4">
          <DialogTitle sx={{ fontFamily: "Poppins, sans-serif" }}>
            Confirm Delete
          </DialogTitle>
          <IconButton onClick={handleDeleteClose} sx={{ color: "white" }}>
            <IoCloseCircleOutline />
          </IconButton>
        </div>
        <DialogContent>
          <p>
            Are you sure you want to delete this item? This action cannot be
            undone.
          </p>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="error"
            sx={{ marginRight: 2 }}
          >
            Delete
          </Button>
          <Button
            onClick={handleDeleteClose}
            variant="outlined"
            color="inherit"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Users;
