import React, { useEffect, useState } from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SelectChangeEvent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { queryConfigs } from "../../hooks/config";
import { useMutationQuery } from "../../hooks/queryHook";
import { showNotification } from "../../services/helper";

type Tprops = {
  open: boolean;
  onClose: () => void;
  userData: TUserData;
};
interface FormValues {
  name: string;
  email: string;
  mobile: string;
  password?: string;
  newPassword?: string;
  newRepassword?: string;
  image: string;
}
interface TUserData {
  id?: number;
  name?: string;
  email?: string;
  mobile?: string;
  password?: string;
  repassword?: string;
  image?: string;
}

const Edituser = ({ open, onClose, userData }: Tprops) => {
  const { mutationFn, invalidateKey } = queryConfigs.updateUser;
  const { mutate } = useMutationQuery({
    key: invalidateKey,
    func: mutationFn,
    onSuccess: () => {
      showNotification("success", "User updated successfully");
      onClose();
    },
  });

  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    email: "",
    mobile: "",
    // password: "",
    // newPassword: "",
    // newRepassword: "",
    image: "",
  });
  useEffect(() => {
    if (userData.id) {
      const filteredData: FormValues = {
        name: userData.name || "",
        email: userData.email || "",
        mobile: userData.mobile || "",
        // password: userData.password || "",
        image: userData.image || "",
      };

      setFormValues(filteredData);
    }
  }, [userData.id, userData]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setFormValues((prevState) => ({ ...prevState, avatar: file }));
    }
  };
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const validateForm = () => {
    const { name, mobile, email, newPassword, newRepassword } = formValues;
    const errors = [];

    if (!name || name.trim() === "") {
      errors.push({ field: "name", message: "Name is required" });
    }
    if (!mobile || mobile.trim() === "" || mobile === "0") {
      errors.push({ field: "mobile", message: "Mobile is required" });
    }
    if (!email || email.trim() === "") {
      errors.push({ field: "email", message: "Email is required" });
    }
    // if (!newPassword || newPassword.trim() === "") {
    //   errors.push({ field: "password", message: "Invalid Password" });
    // } else if (newPassword.trim().length < 8) {
    //   errors.push({
    //     field: "password",
    //     message: "Password length should be at least 8 characters",
    //   });
    // }
    // if (
    //   !newRepassword ||
    //   newRepassword.trim() === "" ||
    //   newRepassword.trim().length < 8
    // ) {
    //   errors.push({
    //     field: "repassword",
    //     message: "Confirm Password length should be at least 8 characters",
    //   });
    // } else if (newPassword?.trim() !== newRepassword.trim()) {
    //   errors.push({ field: "repassword", message: "Passwords don't match" });
    // }

    if (errors.length === 5) {
      return "All fields are missing";
    }

    return errors.length > 0 ? errors.join(", ") : null;
  };
  const handleSubmit = () => {
    const validationError = validateForm();
    if (validationError) {
      setErrorMessage(validationError);
      showNotification("error", "All fields are required");

      return;
    }

    mutate({
      body: {
        ...formValues,
        // password: formValues.newPassword,
        // repassword: formValues.newRepassword,
      },
      id: userData.id,
    });
    // onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="bg-[#1C1C1C] sticky top-0 z-10 flex justify-between items-center px-4">
        <DialogTitle sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}>
          Edit User
        </DialogTitle>
        <IconButton onClick={onClose} sx={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <TextField
          required
          name="name"
          label="Name"
          value={formValues.name}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{
            style: {
              color: "gray",
              fontFamily: "Poppins, sans-serif",
              fontSize: "13px",
            },
          }}
          InputProps={{
            classes: {
              root: "text-black",
              input: "h-8 py-0",
            },
          }}
          inputProps={{
            className: "text-black",
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "1.5rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit",
            },
            "& .MuiInputBase-input": {
              height: "1rem",
              padding: "1rem 1rem",
            },
          }}
          className="rounded-3xl bg-white"
        />
        <TextField
          name="mobile"
          label="Mobile"
          value={formValues.mobile}
          onChange={handleInputChange}
          fullWidth
          required
          margin="dense"
          variant="outlined"
          InputLabelProps={{
            style: {
              color: "gray",
              fontFamily: "Poppins, sans-serif",
              fontSize: "13px",
            },
          }}
          InputProps={{
            inputProps: {
              maxLength: 10,
              pattern: "[0-9]*",
            },
            classes: {
              root: "text-black",
              input: "h-10 py-0",
            },
          }}
          inputProps={{
            className: "text-black font-Poppins",
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "1.5rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit",
            },
            "& .MuiInputBase-input": {
              height: "1rem",
              padding: "1rem 1rem",
            },
          }}
          onInput={(e) => {
            const input = e.target as HTMLInputElement;
            input.value = input.value.replace(/[^0-9]/g, "");
          }}
        />
        <TextField
          name="email"
          label="Email"
          required
          value={formValues.email}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{
            style: {
              color: "gray",
              fontFamily: "Poppins, sans-serif",
              fontSize: "13px",
            },
          }}
          InputProps={{
            classes: {
              root: "text-black",
              input: "h-10 py-0",
            },
          }}
          inputProps={{
            className: "text-black",
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "1.5rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit",
            },
            "& .MuiInputBase-input": {
              height: "1rem",
              padding: "1rem 1rem",
            },
          }}
          className="rounded-3xl bg-white"
        />
        {/* <TextField
          required
          name="newPassword"
          label="Password"
          value={formValues.newPassword}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{
            style: {
              color: "gray",
              fontFamily: "Poppins, sans-serif",
              fontSize: "13px",
            },
          }}
          InputProps={{
            inputProps: { minLength: 8 },
            classes: {
              root: "text-black",
              input: "h-10 py-0",
            },
          }}
          inputProps={{
            className: "text-black",
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "1.5rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit",
            },
            "& .MuiInputBase-input": {
              height: "1rem",
              padding: "1rem 1rem",
            },
          }}
          className="rounded-3xl bg-white"
        />
        <TextField
          required
          name="newRepassword"
          label="Confirm Password"
          value={formValues.newRepassword}
          onChange={handleInputChange}
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{
            style: {
              color: "gray",
              fontFamily: "Poppins, sans-serif",
              fontSize: "13px",
            },
          }}
          InputProps={{
            inputProps: { minLength: 8 },
            classes: {
              root: "text-black",
              input: "h-10 py-0",
            },
          }}
          inputProps={{
            className: "text-black",
          }}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "1.5rem",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "inherit",
            },
            "& .MuiInputBase-input": {
              height: "1rem",
              padding: "1rem 1rem",
            },
          }}
          className="rounded-3xl bg-white"
        /> */}
      </DialogContent>
      <div className="flex justify-center mb-4 items-center">
        <DialogActions>
          <div className="mr-12">
            <button
              onClick={handleSubmit}
              className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
            >
              <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
              <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
              <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                Update
              </span>
              <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
            </button>
          </div>
          <div className="ml-12">
            <button
              onClick={onClose}
              className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
            >
              <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
              <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
              <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                Cancel
              </span>
              <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
            </button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default Edituser;
