import React, { useEffect, useState } from "react";
import { TableCell, TablePagination, TableRow } from "@mui/material";
import CommonTable from "../CommonTable";
import AddMedicine from "./AddMedicine";
import DeleteModal from "../DeleteModal";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { queryConfigs } from "../../hooks/config";
import { useQueryClient } from "@tanstack/react-query";
import { showNotification } from "../../services/helper";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";

interface MedicineRow {
  id: number;
  created_on: string;
  name: string;
  description: string;
}
interface EditMedicine {
  id: number;
  description: string;
  name: string;
}

type MedicinesProps = {
  pageTitle: string;
};

type Header = {
  name: string;
};

const userHeaders: Header[] = [
  { name: "ID" },
  { name: "Name" },
  { name: "Created On" },
  { name: "Action" },
];

const Medicines: React.FC<MedicinesProps> = ({ pageTitle }) => {
  const userHeaderNames: string[] = userHeaders.map((header) => header.name);

  const [toDelete, setToDelete] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState({
    id: 0,
    name: "",
    description: "",
  });
  const [editOpen, setEditOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const [isAddMedicineOpen, setIsAddMedicineOpen] = useState(false);
  const [medicineName, setMedicineName] = useState<string | null>(null);
  const [searchMedicine, setSearchMedicine] = useState<string | null>(null);

  const { queryKey, queryFn } = queryConfigs.getMedicines;
  const queryClient = useQueryClient();
  const limit = pagination.limit;

  const { invalidateKey: deleteInvalidateKey, mutationFn: deleteMutationFn } =
    queryConfigs.deleteMedicine;
  const { mutate: deleteMedicine } = useMutationQuery({
    key: deleteInvalidateKey,
    func: deleteMutationFn,
    onSuccess: () => {
      showNotification("success", "Medicine deleted successfully");
      handleClose();
    },
    onError: (error) => {
      showNotification("error", error.message);
      setIsDeleting(false);
    },
    client: queryClient,
  });

  const { mutationFn: updateFunc, invalidateKey: updateKey } =
    queryConfigs.updateMedicine;
  const { mutate: updateMedicine } = useMutationQuery({
    key: updateKey,
    func: updateFunc,
    onSuccess: () => {
      showNotification("success", "Medicine updated successfully");
      handleEditClose();
    },
    client: queryClient,
  });

  const { data, refetch, isLoading } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      offset: (currentPage - 1) * pagination.limit,
      limit: pagination.limit,
      name: searchMedicine ?? "",
    },
  });

  useEffect(() => {
    setCurrentPage(Math.floor(pagination.offset / pagination.limit) + 1);
  }, [pagination.offset, pagination.limit]);

  const handleSelectDelete = (id: number) => {
    setToDelete(id);
    setOpen(true);
  };

  const handleDelete = () => {
    if (toDelete !== null) {
      setIsDeleting(true);
      deleteMedicine(toDelete);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setToDelete(null);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setEditValue({
      id: 0,
      name: "",
      description: "",
    });
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setCurrentPage(page + 1);
    setPagination((prev) => ({
      ...prev,
      offset: page * pagination.limit,
    }));
  };

  const handleAddClick = () => {
    setIsAddMedicineOpen(true);
  };

  const handleCancelDelete = () => {
    handleClose();
  };

  const handleEditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditValue((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleEditSubmit = () => {
    if (editValue.name !== "" && editValue.id > 0) {
      updateMedicine({
        body: {
          name: editValue.name,
          description: editValue.description,
        },
        id: editValue.id,
      });
    } else {
      showNotification("error", "Name is Compulsory");
    }
  };

  const handleEditCancel = () => {
    handleEditClose();
  };

  const handleEditClick = (medicines: EditMedicine) => {
    if (medicines.id > 0) {
      setEditOpen(true);
      setEditValue({
        id: medicines.id,
        name: medicines.name,
        description: medicines.description,
      });
    } else {
      showNotification("error", "Try Again");
    }
  };

  const handleMedicineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMedicineName(event.target.value);
  };

  const handleSearch = () => {
    if (medicineName !== "" || !medicineName) {
      setSearchMedicine(medicineName);
    }
  };

  const handleSearchClear = () => {
    setMedicineName(null);
    setSearchMedicine(null);
  };
  const handleRefetch = () => {
    handleSearchClear();
    refetch();
  };

  return (
    <div className="w-full overflow-hidden mx-auto relative h-screen">
      <CommonTable
        refetchIcon={true}
        onRefetchClick={handleRefetch}
        tableHeaders={userHeaderNames}
        totalRows={data?.count || 0}
        colSpan={userHeaders.length}
        pageTitle={pageTitle}
        onAddClick={handleAddClick}
        marginTop={16}
        showInchargeFilter={false}
        showDesignationFilter={false}
        showMobileFilter={false}
        children2={
          <>
            <div className=" flex items-center space-x-5 w-full">
              <TextField
                label="Name"
                variant="outlined"
                name="name"
                value={medicineName || ""}
                onChange={handleMedicineChange}
                size="small"
                sx={{
                  width: "20%",
                  "& .MuiInputLabel-root": {
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
              />
              <button onClick={handleSearch} className="search-btn">
                Search
              </button>
              <button onClick={handleSearchClear} className="clear-btn">
                Clear
              </button>
            </div>
          </>
        }
        children3={
          <TablePagination
            component="div"
            count={data?.count || 0}
            page={currentPage - 1}
            rowsPerPage={limit}
            onPageChange={handlePageChange}
            onRowsPerPageChange={(event) => {
              const newLimit = parseInt(event.target.value, 10);
              setPagination((prev) => ({
                ...prev,
                limit: newLimit,
                offset: 0,
              }));
              setCurrentPage(1);
            }}
            sx={{ color: "#38a3a5", font: "Poppins, sans-serif" }}
          />
        }
      >
        {data?.list?.map((medicine: MedicineRow) => (
          <TableRow key={medicine.id}>
            <TableCell align="center" className="capitalize">
              {medicine.id}
            </TableCell>
            <TableCell align="center" className="capitalize">
              {medicine.name}
            </TableCell>
            <TableCell align="center" className="capitalize">
              {medicine.created_on}
            </TableCell>
            <TableCell align="center" className="space-x-3">
              <button
                onClick={() => handleEditClick(medicine)}
                className="edit-btn"
              >
                <FaEdit />
              </button>
              <button
                onClick={() => handleSelectDelete(medicine.id)}
                className="del-btn"
              >
                <MdDeleteForever />
              </button>
            </TableCell>
          </TableRow>
        ))}
      </CommonTable>

      <AddMedicine
        open={isAddMedicineOpen}
        onClose={() => setIsAddMedicineOpen(false)}
      />

      <DeleteModal
        open={open}
        onClose={handleClose}
        title="Delete Medicine ?"
        contentText={
          "Are you sure you want to delete this Medicine?\n" +
          "This action cannot be undone."
        }
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
        isDeleting={isDeleting}
      />
      <Dialog open={editOpen} onClose={handleEditClose}>
        <div className="bg-[#1C1C1C] sticky top-0 z-10 flex justify-between items-center px-4">
          <DialogTitle
            sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
          >
            Edit Medicine
          </DialogTitle>
          <IconButton onClick={handleEditClose} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <TextField
            name="name"
            label="Name"
            value={editValue?.name}
            onChange={handleEditChange}
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            InputProps={{
              classes: {
                root: "text-black",
                input: "h-8 py-0",
              },
            }}
            inputProps={{
              className: "text-black",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
              },
            }}
          />
          <TextField
            name="description"
            label="Description"
            value={editValue?.description}
            onChange={handleEditChange}
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            InputProps={{
              classes: {
                root: "text-black",
                input: "h-8 py-0",
              },
            }}
            inputProps={{
              className: "text-black",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
              },
            }}
          />
        </DialogContent>
        <div className="flex justify-center mb-4 items-center">
          <DialogActions>
            <div className="mr-12">
              <button
                onClick={handleEditSubmit}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Add
                </span>
                <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
              </button>
            </div>
            <div className="ml-12">
              <button
                onClick={handleEditCancel}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Cancel
                </span>
                <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
              </button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default Medicines;
