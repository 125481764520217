import CommonTable from "../CommonTable";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { queryConfigs } from "../../hooks/config";
import { useEffect, useState } from "react";
import { TableHeader } from "../../types/common";
import { useQueryClient } from "@tanstack/react-query";
import AddDoctor from "./AddDoctor";
import { showNotification } from "../../services/helper";
import CloseIcon from "@mui/icons-material/Close";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import DeleteModal from "../DeleteModal";

type DoctorsProps = {
  pageTitle: string;
};
interface DoctorRow {
  id: number;
  created_on?: string;
  name: string;
}
interface EditDoctor {
  id: number | null;
  created_on?: string | null;
  name: string | null;
}

const userHeaders: TableHeader[] = [
  { name: "ID" },
  { name: "Name" },
  { name: "Created On" },
  { name: "Action" },
];

const Doctors: React.FC<DoctorsProps> = ({ pageTitle }) => {
  const [isAddPDoctorOpen, setIsAddPDoctorOpen] = useState(false);
  const [editValue, setEditValue] = useState<EditDoctor | null>({
    id: null,
    name: null,
  });
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [toDelete, setToDelete] = useState<number | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const [doctorName, setDoctorName] = useState<string | null>(null);
  const [searchDoctor, setSearchDoctor] = useState<string | null>(null);

  const { queryKey, queryFn } = queryConfigs.getDoctors;
  const queryClient = useQueryClient();
  const { mutationFn: deleteFunc, invalidateKey: medKey } =
    queryConfigs.deleteDoctor;
  const { mutate: deleteDoctor } = useMutationQuery({
    key: medKey,
    func: deleteFunc,
    onSuccess: () => {
      showNotification("success", "Doctor deleted successfully");
      handleClose();
    },
  });
  const { mutationFn: updateFunc, invalidateKey: updateKey } =
    queryConfigs.updateDoctor;
  const { mutate: updateDoctor } = useMutationQuery({
    key: updateKey,
    func: updateFunc,
    onSuccess: () => {
      showNotification("success", "Doctor updated successfully");
      handleEditClose();
    },
  });
  const limit = pagination.limit;
  const { data, isSuccess, refetch, isLoading } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      offset: (currentPage - 1) * pagination.limit,
      limit: pagination.limit,
      name: searchDoctor ?? "",
    },
  });

  useEffect(() => {
    setCurrentPage(Math.floor(pagination.offset / pagination.limit) + 1);
  }, [pagination.offset, pagination.limit]);

  const handleEditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditValue((prevState) => {
      const updatedState = prevState || {
        id: null,
        name: null,
      };

      // Return the new state with the updated field
      return {
        ...updatedState,
        [name]: value,
      } as EditDoctor;
    });
  };

  const handleAddClick = () => {
    setIsAddPDoctorOpen(true);
  };

  const handleClearEditForm = () => {
    setEditValue({
      id: 0,
      name: "",
    });
  };

  const handleEditClose = () => {
    setEditOpen(false);
    handleClearEditForm();
  };
  const handleEdit = () => {
    if (!editValue?.id && !editValue?.name) {
      showNotification("error", "Check the details and try again");
      return;
    }

    updateDoctor({ id: editValue.id, body: { name: editValue.name } });
  };

  const handleSelectDelete = (id: number) => {
    setToDelete(id);
    setOpen(true);
  };

  const handleDelete = () => {
    if (toDelete !== null) {
      setIsDeleting(true);
      deleteDoctor(toDelete);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setToDelete(null);
  };

  const handleCancelDelete = () => {
    handleClose();
  };

  const handleEditClick = (doctors: DoctorRow) => {
    setEditOpen(true);
    if (doctors?.id > 0) {
      setEditValue({
        id: doctors.id,
        name: doctors.name,
      });
    } else {
      showNotification("error", "Try Again");
    }
  };

  const handleDoctorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDoctorName(event.target.value);
  };

  const handleSearch = () => {
    if (doctorName !== "" || !doctorName) {
      setSearchDoctor(doctorName);
    }
  };

  const handleSearchClear = () => {
    setDoctorName(null);
    setSearchDoctor(null);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setCurrentPage(page + 1);
    setPagination((prev) => ({
      ...prev,
      offset: page * pagination.limit,
    }));
  };

  const userHeaderNames: string[] = userHeaders.map((header) => header.name);

  const handleRefetch = () => {
    refetch();
    handleSearchClear();
  };
  return (
    <div className="w-full overflow-hidden mx-auto relative h-screen">
      <CommonTable
        refetchIcon={true}
        onRefetchClick={handleRefetch}
        tableHeaders={userHeaderNames}
        totalRows={data?.count || 0}
        colSpan={userHeaders.length}
        pageTitle={pageTitle}
        onAddClick={handleAddClick}
        marginTop={16}
        showInchargeFilter={false}
        showDesignationFilter={false}
        showMobileFilter={false}
        children2={
          <>
            <div className=" flex items-center space-x-5 w-full">
              <TextField
                label="Search By Name"
                variant="outlined"
                name="name"
                value={doctorName || ""}
                onChange={handleDoctorChange}
                size="small"
                sx={{
                  width: "20%",
                  "& .MuiInputLabel-root": {
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "13px",
                  },
                }}
              />
              <button onClick={handleSearch} className="search-btn">
                Search
              </button>
              <button onClick={handleSearchClear} className="clear-btn">
                Clear
              </button>
            </div>
          </>
        }
        children3={
          <TablePagination
            component="div"
            count={data?.count || 0}
            page={currentPage - 1}
            rowsPerPage={limit}
            onPageChange={handlePageChange}
            onRowsPerPageChange={(event) => {
              const newLimit = parseInt(event.target.value, 10);
              setPagination((prev) => ({
                ...prev,
                limit: newLimit,
                offset: 0,
              }));
              setCurrentPage(1);
            }}
            sx={{ color: "#38a3a5", font: "Poppins, sans-serif" }}
          />
        }
      >
        {data?.list?.map((doctor: DoctorRow) => (
          <TableRow key={doctor.id}>
            <TableCell align="center" className="capitalize">
              {doctor.id}
            </TableCell>
            <TableCell align="center" className="capitalize">
              {doctor.name}
            </TableCell>
            <TableCell align="center" className="capitalize">
              {doctor.created_on}
            </TableCell>
            <TableCell align="center" className="space-x-3">
              <Tooltip title="Edit" arrow>
                <button
                  onClick={() => handleEditClick(doctor)}
                  className="edit-btn"
                >
                  <FaEdit />
                </button>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <button
                  onClick={() => handleSelectDelete(doctor?.id)}
                  className="del-btn"
                >
                  <MdDeleteForever />
                </button>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </CommonTable>
      <AddDoctor
        open={isAddPDoctorOpen}
        onClose={() => setIsAddPDoctorOpen(false)}
      />
      <DeleteModal
        open={open}
        onClose={handleClose}
        title="Delete Doctor ?"
        contentText={
          "Are you sure you want to delete this Doctor?\n" +
          "This action cannot be undone."
        }
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
        isDeleting={isDeleting}
      />
      <Dialog open={editOpen} onClose={handleEditClose}>
        <div className="bg-[#1C1C1C] sticky top-0 z-10 flex justify-between items-center px-4">
          <DialogTitle
            sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
          >
            Edit Doctor's Details
          </DialogTitle>
          <IconButton onClick={handleEditClose} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <TextField
            name="name"
            label="Name"
            value={editValue?.name}
            onChange={handleEditChange}
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            InputProps={{
              classes: {
                root: "text-black",
                input: "h-8 py-0",
              },
            }}
            inputProps={{
              className: "text-black",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
              },
            }}
          />
        </DialogContent>
        <div className="flex justify-center mb-4 items-center">
          <DialogActions>
            <div className="mr-12">
              <button
                onClick={handleEdit}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Save
                </span>
                <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
              </button>
            </div>
            <div className="ml-12">
              <button
                onClick={handleEditClose}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Cancel
                </span>
                <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
              </button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default Doctors;
