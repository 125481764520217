import { useRef } from "react";
import { Modal, Backdrop, Box, Paper } from "@mui/material";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery } from "../../hooks/queryHook";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";
import { BillingSummary } from "../../types/common";

type EditBillingProps = {
  open: boolean;
  onClose: () => void;
  ptInfo: BillingSummary;
};
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
interface FormValues {
  id?: number | null;
  patient: number | null;
  payable_amount: number | null;
  paid_amount: number | null;
  month: number | null;
  year: number | null;
  received_by: number | null;
}
type EmpRow = {
  id: number;
  name: string;
  created_on: string;
  email: string;
  mobile: string;
  address: string;
  designation: number;
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 650,
  bgcolor: "background.paper",
  backdropFilter: "blur(3px)",
  borderRadius: 2,
  overflowY: "auto",
};
const EditBilling = ({ open, onClose, ptInfo }: EditBillingProps) => {
  const { queryFn, queryKey } = queryConfigs.getBillingById;
  const { queryFn: payFunc, queryKey: payKey } = queryConfigs.addPayment;
  const { invalidateKey, mutationFn } = queryConfigs.updateBill;
  const { queryKey: employees, queryFn: employeesFunc } =
    queryConfigs.useGetEmployees;
  const {
    data: billData,
    isLoading,
    refetch,
  } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: { id: ptInfo?.billing_id },
    isEnabled: !!ptInfo?.billing_id,
  });

  let data;
  if (billData) {
    data = billData.data;
  }
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <button className="generate-btn m-8" onClick={() => reactToPrintFn()}>
          Print
        </button>

        <>
          <Paper ref={contentRef}>
            <section
              id="vitalTable"
              className="w-[210mm] h-screen p-[4mm] overflow-auto mx-auto"
            >
              <section className="h-[150px] flex-col rounded-lg flex justify-center items-center"></section>

              <div className="mt-4 flex justify-between bg-gray-100 p-4 rounded-lg shadow-md border ">
                <section className="w-auto">
                  <h2 className="text-lg font-semibold text-gray-700 mb-2">
                    Issued to:
                  </h2>
                  <div className="text-sm text-gray-600 mb-1">
                    <span className="font-medium text-gray-800">
                      Patient ID:
                    </span>{" "}
                    {data?.patient || "N/A"}
                  </div>
                  <div className="text-sm text-gray-600 mb-1">
                    {data?.patient_name || "N/A"}
                  </div>
                  <div className="text-sm text-gray-600 mb-1">
                    {data?.address || "N/A"}
                  </div>
                  <div className="text-sm text-gray-600">
                    {data?.mobile || "N/A"}
                  </div>
                </section>
                <section className="w-[36%] text-left">
                  <h2 className="text-lg font-semibold text-gray-700 mb-2">
                    Invoice No:{" "}
                    {data?.organisation_name &&
                      data.organisation_name.charAt(0).toUpperCase()}
                    {"-"}#{data?.id}
                  </h2>
                  <div className="text-sm text-gray-600 mb-1">
                    <span className="font-medium text-gray-800">Paid On:</span>{" "}
                    {dayjs(data?.paid_on).format("DD MMMM YYYY, hh:mm A")}
                  </div>
                  <div className="text-sm text-gray-600 mb-1">
                    <span className="font-medium text-gray-800">
                      Collected By:
                    </span>{" "}
                    {data?.collected_by || "N/A"}
                  </div>
                </section>
              </div>

              <section className="my-10 p-4 rounded-lg">
                <div className="overflow-auto max-h-[380px]">
                  <table className="w-full border-collapse border text-center mx-auto">
                    <thead className="bg-gray-300 sticky text-sm top-0">
                      <tr>
                        <th className="p-2 text-center">Month</th>
                        <th className="p-2 text-center">Year</th>
                        <th className="p-2 text-center">Adm Fee</th>
                        <th className="p-2 text-center">Due</th>
                        <th className="p-2 text-center">Extra Charge</th>
                        <th className="p-2 text-center">Discount</th>
                        <th className="p-2 text-center">Paid Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-xs">
                        <td className="p-2 capitalize">
                          {dayjs()
                            .month(data?.month - 1)
                            .format("MMMM")}
                        </td>
                        <td className="p-2 capitalize">{data?.year}</td>
                        <td className="p-2 capitalize">
                          ₹{data?.admission_fee}/-
                        </td>
                        <td className="p-2 capitalize">₹{data?.dues}/-</td>
                        <td className="p-2 capitalize">
                          ₹{data?.extra_charge}/-
                        </td>
                        <td className="p-2 capitalize">
                          ₹{data?.deducted_amount}/-
                        </td>
                        <td className="p-2 capitalize">
                          ₹{data?.paid_amount}/-
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={7} className="h-20"></td>{" "}
                        {/* Spacer row */}
                      </tr>
                      <tr className="border border-gray-300 mt-[25px]">
                        <td className="p-2 capitalize font-bold" colSpan={6}>
                          Total
                        </td>
                        <td className="p-2 capitalize font-bold">
                          ₹
                          {data?.admission_fee +
                            data?.dues +
                            data?.extra_charge -
                            data?.deducted_amount}
                          /-
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>

              <section className="h-20 flex justify-end">
                <div className="flex items-center justify-between px-6">
                  <div className="text-sm text-gray-600 mb-1">
                    <span className="font-medium text-lg text-gray-800">
                      Signature of Cashier
                    </span>
                  </div>
                </div>
              </section>
            </section>
          </Paper>
        </>
      </Box>
    </Modal>
  );
};

export default EditBilling;
