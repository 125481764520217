import React, { useEffect, useState } from "react";
import {
  TextField,
  DialogActions,
  DialogTitle,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Modal,
  Backdrop,
  Box,
  Autocomplete,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { showNotification } from "../../services/helper";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type TreatmentMedicine = {
  id: number;
  medicine: number;
  treatment: number;
  type: string;
  description?: string;
  name?: string;
  created_on: string;
  prescribed_by: number;
  timing: string;
  dose: string;
};

type Treatment = {
  id: number;
  patient: number;
  remarks: string;
  created_on: string;
  medicines: TreatmentMedicine[];
};

type MedicineData = {
  count: number;
  list: Medicine[];
  success: boolean;
};

type TreatmentChart = {
  id: number;
  patient: number;
  remarks?: string;
  created_on?: string;
  administered: string;
  medicines: Medicine[];
};
// interface Medicine {
//     id: number;
//     name: string;
//     treatment: number;
//     description?: string;
//     created_on?: string;
//     prescribed_by?: number;
//     timing?: string;
//     type?: string;
//     dose?: string;
// }
type Medicine = {
  id: number;
  name: string;
  treatment: number;
  description?: string;
  created_on?: string;
  prescribed_by?: number;
  timing?: string;
  type?: string;
  dose?: string;
};

type AddEmpProps = {
  open: boolean;
  onClose: () => void;
  treatmentData: any;
};

interface DoctorRow {
  id: number;
  created_on: string;
  name: string;
}
type TransformedMedicine = {
  id?: number;
  medicine: number;
  created_on: string;
  name?: string;
  description?: string;
  prescribed_by?: number | null;
  timing?: string;
  type?: string;
  dose?: string;
};

type MergedMedicine = TreatmentMedicine & Medicine;

const EditTreatment = ({ open, onClose, treatmentData }: AddEmpProps) => {
  const [formattedDate, setFormattedDate] = useState<string | undefined>(
    undefined
  );

  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(
    null
  );
  useEffect(() => {
    if (treatmentData.administered) {
      setSelectedDate(treatmentData.administered);
    }
  }, [treatmentData]);

  const params = useParams();
  const { queryKey: medicines, queryFn: medFunc } = queryConfigs.getMedicines;
  const { data: medicineData } = useGetQuery({
    key: medicines,
    func: medFunc,
    params: { offset: 0, limit: 100 },
  });

  const transformedMedicines: TransformedMedicine[] = medicineData?.list?.map(
    (medicine: Medicine) => ({
      medicine: medicine.id,
      created_on: medicine.created_on,
      name: medicine.name,
      description: medicine.description,
      prescribed_by: medicine.prescribed_by ? medicine.prescribed_by : null,
      timing: "",
      type: "",
      dose: "",
    })
  );

  const { queryKey: doctors, queryFn: doctorFunc } = queryConfigs.getDoctors;
  const { data: doctorData } = useGetQuery({
    key: doctors,
    func: doctorFunc,
  });

  const { invalidateKey, mutationFn } = queryConfigs.updateTreatment;
  const { mutate } = useMutationQuery({
    key: invalidateKey,
    func: mutationFn,
    onSuccess: () => {
      showNotification("success", "Treatment Records Updated successfully");
      onClose();
    },
  });

  const handleClearForm = () => {
    setSelectedMedicines([]);
    setRemarks("");
  };

  const handleSubmit = () => {
    if (selectedMedicines.length === 0) {
      showNotification("error", "Please select at least one medicine.");
      return;
    }
    const filteredMedicines = selectedMedicines?.map(
      ({ created_on, description, ...rest }) => ({
        id: rest.id || null,
        medicine: rest.medicine,
        treatment: treatmentData.id,
        name: rest.name,
        prescribed_by: rest.prescribed_by || null,
        type: rest.type || null,
        timing: rest.timing || null,
        dose: rest.dose || null,
      })
    );
    if (
      !formattedDate ||
      dayjs(formattedDate, "YYYY-MM-DD HH:mm:ss", true).isValid() === false
    ) {
      showNotification("error", "Please provide a valid administered date.");
      return;
    }
    const updatedForm = {
      patient: parseInt(params.id || "0"),
      remarks: remarks.trim(),
      medicines: filteredMedicines,
      administered: formattedDate,
    };

    mutate({ body: updatedForm, id: treatmentData.id });
    onClose();
    handleClearForm();
  };

  const handleCancel = () => {
    onClose();
    handleClearForm();
  };

  const [selectedMedicines, setSelectedMedicines] = useState<
    TransformedMedicine[]
  >([]);
  const [remarks, setRemarks] = useState<string>("");

  useEffect(() => {
    if (treatmentData) {
      const medicineMap = new Map<number, string>();
      transformedMedicines?.forEach((medicine) => {
        medicineMap.set(medicine.medicine, medicine.name ?? "");
      });

      // Update treatmentData.medicines with names from transformedMedicines
      treatmentData?.medicines?.forEach((medicineItem: TransformedMedicine) => {
        const name = medicineMap.get(medicineItem.medicine);
        if (name) {
          medicineItem.name = name;
        }
      });

      const medArr = Array.isArray(treatmentData.medicines)
        ? treatmentData.medicines
        : [];
      setSelectedMedicines([...medArr]);
      // setSelectedMedicines([...treatmentData?.medicine]);
      setRemarks(treatmentData.remarks || "");
    }
  }, [treatmentData]);

  const handleRemarksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemarks(event.target.value);
  };

  const handlePrescriberChange = (id: number, value: number) => {
    setSelectedMedicines((prev) =>
      prev?.map((medicine) =>
        medicine.id === id ? { ...medicine, prescribed_by: value } : medicine
      )
    );
  };

  const handleTypeChange = (id: number, value: string) => {
    setSelectedMedicines((prev) =>
      prev?.map((medicine) =>
        medicine.id === id ? { ...medicine, type: value || "" } : medicine
      )
    );
  };

  const handleDoseChange = (id: number, value: string) => {
    setSelectedMedicines((prev) =>
      prev?.map((medicine) =>
        medicine.id === id ? { ...medicine, dose: value || "" } : medicine
      )
    );
  };

  const handleTimingChange = (id: number, value: string) => {
    setSelectedMedicines((prev) =>
      prev?.map((medicine) =>
        medicine.id === id ? { ...medicine, timing: value || "" } : medicine
      )
    );
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date);
      const formatted = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
      setFormattedDate(formatted);
    } else {
      setFormattedDate(undefined);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "600px",
    height: selectedMedicines.length === 0 ? "52%" : "90%",
    bgcolor: "background.paper",
    backdropFilter: "blur(3px)",
    borderRadius: 2,
    overflowY: "auto",
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <div className="bg-[#1C1C1C] sticky top-0 z-10 flex justify-between items-center px-4">
          <DialogTitle
            sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
          >
            Update Treatment Record
          </DialogTitle>
          <IconButton onClick={onClose} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <Box sx={{ padding: 2 }}>
          <div
            style={{ position: "relative", zIndex: "999" }}
            className="w-full z-50"
          >
            <DatePicker
              selected={selectedDate ? dayjs(selectedDate).toDate() : null}
              onChange={handleDateChange}
              dateFormat="dd, MMMM, yyyy"
              placeholderText="Medicine Recorded Date"
              maxDate={dayjs().toDate()}
              className="px-6 py-4 w-full h-12 border border-gray-200 rounded-3xl placeholder-gray-500"
              popperPlacement="bottom-start"
              wrapperClassName="w-full"
            />
          </div>
          <Autocomplete
            multiple
            id="medicines-autocomplete"
            options={transformedMedicines}
            getOptionLabel={(option) => option.name || ""}
            value={selectedMedicines}
            onChange={(event, newValue) => setSelectedMedicines(newValue)}
            isOptionEqualToValue={(option, value) =>
              option.medicine === value.medicine
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Medicines"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontFamily: "Poppins",
                    fontSize: "13px",
                  },
                }}
              />
            )}
            fullWidth
            sx={{
              width: "100%",
              marginTop: 2,
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
                borderRadius: "1.5rem",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
                textTransform: "capitalize",
              },
              "& .MuiSelect-icon": {
                color: "gray",
              },
            }}
          />
          {selectedMedicines.length > 0 && (
            <Box sx={{ marginTop: 2, overflowY: "auto" }}>
              {selectedMedicines?.map((medicine) => (
                <Box key={medicine.id} sx={{ marginBottom: 2, padding: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      textTransform: "capitalize",
                    }}
                  >
                    {medicine.name}
                  </Typography>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: 1 }}
                  >
                    <InputLabel
                      style={{
                        color: "gray",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13px",
                      }}
                    >
                      Prescriber
                    </InputLabel>
                    <Select
                      value={medicine.prescribed_by ?? ""}
                      onChange={(e) => {
                        if (medicine.id !== undefined) {
                          handlePrescriberChange(
                            medicine.id,
                            e.target.value as number
                          );
                        }
                      }}
                      label="Prescriber"
                      sx={{
                        width: "100%",
                        marginBottom: 1,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "1.5rem",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "inherit",
                          borderRadius: "1.5rem",
                        },
                        "& .MuiInputBase-input": {
                          height: "1rem",
                          padding: "1rem 1rem",
                          textTransform: "capitalize",
                        },
                        "& .MuiSelect-icon": {
                          color: "gray",
                        },
                      }}
                    >
                      {doctorData?.list?.map((doctor: DoctorRow) => (
                        <MenuItem key={doctor.id} value={doctor.id}>
                          {doctor.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    label="Type"
                    value={medicine.type ?? ""}
                    onChange={(e) => {
                      if (medicine.id !== undefined) {
                        handleTypeChange(medicine.id, e.target.value);
                      }
                    }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13px",
                      },
                    }}
                    sx={{
                      width: "100%",
                      marginBottom: 1,
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "1.5rem",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "inherit",
                        borderRadius: "1.5rem",
                      },
                      "& .MuiInputBase-input": {
                        height: "1rem",
                        padding: "1rem 1rem",
                        textTransform: "capitalize",
                      },
                      "& .MuiSelect-icon": {
                        color: "gray",
                      },
                    }}
                  />
                  <TextField
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    label="Dose"
                    value={medicine.dose ?? ""}
                    onChange={(e) => {
                      if (medicine.id !== undefined) {
                        handleDoseChange(medicine.id, e.target.value);
                      }
                    }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13px",
                      },
                    }}
                    sx={{
                      width: "100%",
                      marginBottom: 1,
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "1.5rem",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "inherit",
                        borderRadius: "1.5rem",
                      },
                      "& .MuiInputBase-input": {
                        height: "1rem",
                        padding: "1rem 1rem",
                        textTransform: "capitalize",
                      },
                      "& .MuiSelect-icon": {
                        color: "gray",
                        fontFamily: "Popins, sans-serif",
                      },
                    }}
                  />
                  <FormControl
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: 1 }}
                  >
                    <InputLabel
                      sx={{
                        color: "gray",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13px",
                      }}
                    >
                      Timing
                    </InputLabel>
                    <Select
                      value={medicine.timing ?? ""}
                      onChange={(e) => {
                        if (medicine.id !== undefined) {
                          handleTimingChange(
                            medicine.id,
                            e.target.value as string
                          );
                        }
                      }}
                      label="Timing"
                      sx={{
                        width: "100%",
                        marginBottom: 1,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "1.5rem",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "inherit",
                          borderRadius: "1.5rem",
                        },
                        "& .MuiInputBase-input": {
                          height: "1rem",
                          padding: "1rem 1rem",
                          textTransform: "capitalize",
                        },
                        "& .MuiSelect-icon": {
                          color: "gray",
                          fontFamily: "Popins, sans-serif",
                        },
                      }}
                    >
                      <MenuItem value="BD">BD</MenuItem>
                      <MenuItem value="HS">HS</MenuItem>
                      <MenuItem value="OD">OD</MenuItem>
                      <MenuItem value="SOS">SOS</MenuItem>
                      <MenuItem value="TIDOR">TIDOR</MenuItem>
                      <MenuItem value="TDS">TDS</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              ))}
            </Box>
          )}
          <TextField
            label="Remarks"
            variant="outlined"
            multiline
            maxRows={2}
            value={remarks}
            onChange={handleRemarksChange}
            fullWidth
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            sx={{
              width: "100%",
              marginTop: 2,
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
                textTransform: "capitalize",
              },
            }}
          />
          <div className="flex justify-center my-4 items-center">
            <DialogActions>
              <div className="mr-12">
                <button
                  onClick={handleSubmit}
                  className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
                >
                  <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                  <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                  <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                    Update
                  </span>
                  <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
                </button>
              </div>
              <div className="ml-12">
                <button
                  onClick={handleCancel}
                  className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
                >
                  <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                  <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                  <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                    Cancel
                  </span>
                  <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
                </button>
              </div>
            </DialogActions>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditTreatment;
