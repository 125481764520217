import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Backdrop,
  Modal,
  Box,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery } from "../../hooks/queryHook";
import dayjs from "dayjs";
import GenericPdfDownloader from "../GenericPdfDownloader";
import { IoCloseCircleOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type Medicine = {
  id: number;
  medicine: number;
  treatment: number;
  created_on: string;
  name?: string;
  prescribed_by: number | null;
  type?: string;
  timing?: string;
  dose?: string;
};

type Treatment = {
  id: number;
  patient: number;
  remarks: string;
  created_on: string;
  medicines: Medicine[];
};

type TreatmentResponse = {
  count: number;
  list: Treatment[];
  success: boolean;
};

type DoctorRow = {
  id: number;
  created_on: string;
  name: string;
};

interface TreatmentTableProps {
  treatments?: TreatmentResponse;
  open: boolean;
  pTId: number;
  onClose: () => void;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "90%",
  bgcolor: "background.paper",
  backdropFilter: "blur(3px)",
  borderRadius: 2,
  overflowY: "auto",
};

const getMedicineName = (medicineId: number, medicineList: Medicine[]) => {
  return medicineList.find((med) => med.id === medicineId)?.name || "";
};

const getDoctorName = (doctorId: number | null, doctorList: DoctorRow[]) => {
  return doctorList.find((doc) => doc.id === doctorId)?.name || "";
};

const TreatmentTable: React.FC<TreatmentTableProps> = ({
  open,
  onClose,
  pTId,
}: TreatmentTableProps) => {
  const [currentDate, setCurrentDate] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartStartDate, setChartStartDate] = useState<string | null>(null);
  const [chartEndDate, setChartEndDate] = useState<string | null>(null);

  useEffect(() => {
    const today = dayjs().format("YYYY-MM-DD");
    setCurrentDate(today);
  }, []);
  useEffect(() => {
    if (!pTId || pTId <= 0) {
      onClose();
    }
  }, [pTId]);

  const { queryKey: medicines, queryFn: medFunc } = queryConfigs.getMedicines;
  const { data: medicineData } = useGetQuery({
    key: medicines,
    func: medFunc,
    params: { offset: 0, limit: 100 },
  });

  const { queryKey: doctors, queryFn: doctorFunc } = queryConfigs.getDoctors;
  const { data: doctorData } = useGetQuery({
    key: doctors,
    func: doctorFunc,
  });
  const { queryKey, queryFn } = queryConfigs.getTreatments;

  const { data, refetch, isLoading } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      offset: 0,
      limit: 10,
      patient: pTId,
      startDate: chartStartDate,
      endDate: chartEndDate,
    },
    // isEnabled: pTId <= 0 ? true : false,
    isEnabled: pTId != null && chartStartDate != null && chartEndDate != null,
  });

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChartDateRange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    const formattedStart = start ? dayjs(start).format("YYYY-MM-DD") : null;
    const formattedEnd = end ? dayjs(end).format("YYYY-MM-DD") : null;

    if (start) {
      setChartStartDate(formattedStart);
      setChartEndDate(formattedEnd);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <div className="bg-[#1C1C1C] sticky top-0 z-30 flex justify-between items-center px-4">
          <DialogTitle
            sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
          >
            Download Treatment Charts
          </DialogTitle>
          <IconButton onClick={onClose} sx={{ color: "white" }}>
            <IoCloseCircleOutline />
          </IconButton>
        </div>
        <div className="w-[210mm] p-[4mm] mx-auto">
          <div className=" flex items-center space-x-5 ">
            <DatePicker
              selected={chartStartDate ? dayjs(chartStartDate).toDate() : null}
              onChange={handleChartDateRange}
              startDate={
                chartStartDate ? dayjs(chartStartDate).toDate() : undefined
              }
              endDate={chartEndDate ? dayjs(chartEndDate).toDate() : undefined}
              selectsRange
              dateFormat="yyyy-MM-dd"
              placeholderText="Select Date Range"
              className="w-[300px] border rounded  py-2 px-4"
            />
            <GenericPdfDownloader
              rootElementId1="treatmentTable"
              downloadFileName={`TreatmentChart-${currentDate}`}
            />
          </div>
        </div>
        <section id="treatmentTable" className="w-[210mm] p-[4mm] mx-auto">
          <div className="mt-4 w-full ">
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Medicine</TableCell>
                    <TableCell align="center">Prescribed By</TableCell>
                    <TableCell align="center">Type</TableCell>
                    <TableCell align="center">Timing</TableCell>
                    <TableCell align="center">Dose</TableCell>
                    {/* <TableCell align='center'>Created On</TableCell> */}
                  </TableRow>
                </TableHead>
                {data && (
                  <TableBody>
                    {data?.list?.map((treatment: Treatment) => (
                      <React.Fragment key={treatment.id}>
                        {/* <TableRow>
                    <TableCell colSpan={8}>
                    <Typography variant="subtitle1">Treatment ID: {treatment.id}</Typography>
                    </TableCell>
                    </TableRow> */}
                        {treatment.medicines.map((medicine) => (
                          <TableRow key={medicine.id}>
                            <TableCell align="left" className="capitalize">
                              {getMedicineName(
                                medicine.medicine,
                                medicineData.list
                              )}
                            </TableCell>

                            <TableCell align="center" className="capitalize">
                              {getDoctorName(
                                medicine.prescribed_by,
                                doctorData.list
                              ) || "-"}
                            </TableCell>
                            <TableCell align="center" className="capitalize">
                              {medicine.type || "-"}
                            </TableCell>
                            <TableCell align="center">
                              {medicine.timing || "-"}
                            </TableCell>
                            <TableCell align="center">
                              {medicine.dose || "-"}
                            </TableCell>
                            {/* <TableCell align='center'>
                            {dayjs(medicine.created_on).format("ll")}
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </section>
      </Box>
    </Modal>
  );
};

export default TreatmentTable;
