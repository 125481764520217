import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { useGetQuery } from "../../hooks/queryHook";
import { queryConfigs } from "../../hooks/config";
import { FaUserFriends } from "react-icons/fa";
import dayjs from "dayjs";
import PaymentStatusChips from "../PaymentStatusChips";
import { MdReceipt } from "react-icons/md";
import { useNavigate } from "react-router-dom";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

type BillingLedgersProps = {
  pageTitle: string;
};

type Header = {
  name: string;
};

const userHeaders: Header[] = [
  { name: "Patient ID" },
  { name: "Name" },
  { name: "Adm Fee" },
  { name: "Total Due" },
  { name: "Extra Charges" },
  { name: "Discounts" },
  { name: "Status" },
  { name: "Due Date" },
];
const headerTitles = [
  { id: 1, title: "Pt ID", width: "100px" },
  { id: 2, title: "Pt Name" },
  { id: 3, title: "Adm Fee" },
  { id: 4, title: "Due" },
  { id: 5, title: "Extra Charge" },
  { id: 7, title: "Discount" },
  { id: 6, title: "Toal Paid Amount" },
  { id: 8, title: "Next Due Date", width: "150px" },
  { id: 9, title: "Status" },
  { id: 10, title: "Action" },
];

export const tableHeaderStyle = {
  fontFamily: "Poppins, sans-serif",
  fontWeight: 500,
  color: "#353535",
  fontSize: "12px",
};
type BillingSummary = {
  admission: number;
  patient_id: number;
  patient_name: string;
  admission_fee: number;
  total_due: number;
  total_extra_charge: number;
  total_paid_amount: number;
  total_card_amount: number;
  total_cash_amount: number;
  total_upi_amount: number;
  total_deducted_amount: number;
  total_paid: number;
  total_fees: number;
  total_billings: number;
  total_payments: number;
  status: string;
  due_date?: string;
};

type StatusOption = "Fulfilled" | "Due" | "Warning" | "High Alert" | "Critical";

const BillingLedger: React.FC<BillingLedgersProps> = ({ pageTitle }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const limit = pagination.limit;
  const [patientSearch, setPatientSearch] = useState<{
    id: number | null;
    name: string | null;
    status: StatusOption | null;
  }>({
    id: null,
    name: null,
    status: null,
  });
  const [searchParams, setSearchParams] = useState<{
    id: number | null;
    name: string | null;
    status: StatusOption | null;
  }>({
    id: null,
    name: null,
    status: null,
  });
  const { queryKey, queryFn } = queryConfigs.getBillingSummary;
  const userHeaderNames: string[] = userHeaders.map((header) => header.name);
  const { data, isLoading, refetch } = useGetQuery({
    key: queryKey,
    func: queryFn,
    params: {
      offset: (currentPage - 1) * pagination.limit,
      limit: pagination.limit,
      name: searchParams.name ?? "",
      patient: searchParams.id,
      status: searchParams.status?.toLowerCase(),
    },
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPatientSearch((prevState) => ({
      ...prevState,
      [name]: name === "id" ? (value ? Number(value) : null) : value,
    }));
  };

  const handleSearch = () => {
    setSearchParams(patientSearch);
  };
  const handleClear = () => {
    setPatientSearch({
      id: null,
      name: null,
      status: null,
    });
    setSearchParams({
      id: null,
      name: null,
      status: null,
    });
  };
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setCurrentPage(page + 1);
    setPagination((prev) => ({
      ...prev,
      offset: page * pagination.limit,
    }));
  };
  const handleChangeStatus = (event: SelectChangeEvent<StatusOption>) => {
    setPatientSearch((prevState) => ({
      ...prevState,
      status: event.target.value as StatusOption,
    }));
  };
  const handleBillClick = (billing: BillingSummary) => {
    if (billing.patient_id > 0) {
      navigate(
        `/admin/in-patient/${billing.patient_id}/billings/${billing.admission}`
      );
    }
  };
  return (
    <div className="w-full overflow-auto mx-auto relative h-screen">
      <div className="flex w-full mt-28">
        <div className="w-1/4 bg-[#B71C1C] border border-[#B71C1C] rounded-lg drop-shadow-lg h-16 m-2 flex items-center justify-center">
          <FaUserFriends className="absolute -top-4 left-2 bg-white rounded-lg border border-[#B71C1C] p-4 drop-shadow-lg text-[#B71C1C] text-7xl" />
          <span className="absolute top-2 right-2 capitalize text-sm font-light text-white font-Poppins">
            Critical patient
          </span>
          <span className="absolute top-8 right-2 capitalize text-xl font-semibold text-white font-Poppins">
            {data?.status_counts?.critical ? data?.status_counts?.critical : 0}
          </span>
        </div>
        <div className="w-1/4 bg-[#FF5722] border border-[#FF5722] rounded-lg drop-shadow-lg h-16 m-2 flex items-center justify-center">
          <FaUserFriends className="absolute -top-4 left-2 bg-white rounded-lg border border-[#FF5722] p-4 drop-shadow-lg text-[#e85d04] text-7xl" />
          <span className="absolute top-2 right-2 capitalize text-sm font-light text-white font-Poppins">
            High Alert patient
          </span>
          <span className="absolute top-8 right-2 capitalize text-xl font-semibold text-white font-Poppins">
            {data?.status_counts?.high_alert
              ? data?.status_counts?.high_alert
              : 0}
          </span>
        </div>
        <div className="w-1/4 bg-[#FFA000] border border-[#FFA000] rounded-lg drop-shadow-lg h-16 m-2 flex items-center justify-center">
          <FaUserFriends className="absolute -top-4 left-2 bg-white border border-[#FFA000] rounded-lg p-4 drop-shadow-lg text-[#FFA000] text-7xl" />
          <span className="absolute top-2 right-2 capitalize text-sm font-light text-white font-Poppins">
            Warning
          </span>
          <span className="absolute top-8 right-2 capitalize text-xl font-semibold text-white font-Poppins">
            {data?.status_counts?.warning ? data?.status_counts?.warning : 0}
          </span>
        </div>
        <div className="w-1/4 bg-[#FFC107] border border-[#FFC107] rounded-lg drop-shadow-lg h-16 m-2 flex items-center justify-center">
          <FaUserFriends className="absolute -top-4 left-2 bg-white rounded-lg border border-[#FFC107] p-4 drop-shadow-lg text-[#FFC107] text-7xl" />
          <span className="absolute top-2 right-2 capitalize text-sm font-light text-white text-end font-Poppins">
            Due
          </span>
          <span className="absolute top-8 right-2 capitalize text-xl font-semibold text-white font-Poppins">
            {data?.status_counts?.due ? data?.status_counts?.due : 0}
          </span>
        </div>
      </div>
      <div className=" bg-white rounded-lg">
        <div className=" flex items-center space-x-5 w-full px-6 py-6">
          <TextField
            label="Patient Name"
            variant="outlined"
            name="name"
            value={patientSearch.name || ""}
            onChange={handleChange}
            size="small"
            sx={{
              width: "20%",
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
          />
          <TextField
            label="Patient ID"
            variant="outlined"
            name="id"
            value={patientSearch.id !== null ? patientSearch.id.toString() : ""}
            onChange={handleChange}
            size="small"
            sx={{
              width: "20%",
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
          />
          <FormControl
            size="small"
            margin="dense"
            sx={{
              width: 120,
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
              "& .MuiSelect-root": {
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
          >
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              labelId="status-select-label"
              value={patientSearch.status ?? ""}
              onChange={handleChangeStatus}
              label="Status"
            >
              <MenuItem value="Fulfilled">Fulfilled</MenuItem>
              <MenuItem value="Due">Due</MenuItem>
              <MenuItem value="Warning">Warning</MenuItem>
              <MenuItem value="High Alert">High Alert</MenuItem>
              <MenuItem value="Critical">Critical</MenuItem>
            </Select>
          </FormControl>

          <button onClick={handleSearch} className="search-btn">
            Search
          </button>
          <button onClick={handleClear} className="clear-btn">
            Clear
          </button>
        </div>
        <TablePagination
          component="div"
          count={data?.count || 0}
          page={currentPage - 1}
          rowsPerPage={limit}
          onPageChange={handlePageChange}
          onRowsPerPageChange={(event) => {
            const newLimit = parseInt(event.target.value, 10);
            setPagination((prev) => ({
              ...prev,
              limit: newLimit,

              offset: 0,
            }));
            setCurrentPage(1);
          }}
          sx={{ color: "#38a3a5", font: "Poppins, sans-serif" }}
        />
        <TableContainer
          component={Paper}
          sx={{
            minHeight: 380,
            maxHeight: 380,
            overflow: "auto",
            marginTop: 2,
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ width: "100%", overflow: "auto" }}
            size="medium"
          >
            <TableHead sx={{ borderBottom: "none" }}>
              <TableRow>
                {headerTitles.map(({ id, title, width }) => (
                  <TableCell
                    key={id}
                    sx={{
                      ...tableHeaderStyle,
                      width: width || "auto",
                    }}
                    align="center"
                    className="uppercase font-semibold"
                  >
                    {title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.list?.map((billing: BillingSummary) => (
                <TableRow key={billing.patient_id}>
                  <TableCell align="center" className="capitalize">
                    {billing.patient_id}
                  </TableCell>
                  <TableCell align="center" className="capitalize text-xs">
                    {billing.patient_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: 100,
                    }}
                    className="capitalize"
                  >
                    ₹{billing.admission_fee}/-
                  </TableCell>
                  <TableCell align="center" className="capitalize">
                    ₹{billing.total_due || 0}/-
                  </TableCell>
                  <TableCell align="center" className="capitalize">
                    ₹{billing.total_extra_charge}/-
                  </TableCell>

                  <TableCell align="center" className="capitalize">
                    ₹{billing.total_deducted_amount}/-
                  </TableCell>
                  <TableCell align="center" className="capitalize">
                    ₹{billing.total_payments || 0}/-
                  </TableCell>
                  <TableCell align="center" className="capitalize">
                    {dayjs(billing.due_date).format("DD-MMM-YYYY")}
                  </TableCell>
                  <TableCell align="center" className="capitalize">
                    <PaymentStatusChips status={billing.status} />
                  </TableCell>
                  <TableCell align="center" className="capitalize">
                    <Tooltip title="Billing" arrow>
                      <button
                        onClick={() => {
                          handleBillClick(billing);
                        }}
                        className="view-btn"
                      >
                        <MdReceipt />
                      </button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default BillingLedger;
