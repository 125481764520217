import React, { useState } from "react";
import {
  TextField,
  DialogActions,
  DialogTitle,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Modal,
  Backdrop,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import { showNotification } from "../../services/helper";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type AddEmpProps = {
  open: boolean;
  onClose: () => void;
};

type DesignationMenu = {
  id: number;
  name: string;
  organisation: number;
  ped_on: string;
};
type EmpRow = {
  id: number;
  name: string;
  administered_on: string;
  email: string;
  mobile: string;
  address: string;
  designation: number;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit",
  height: 500,
  bgcolor: "background.paper",
  backdropFilter: "blur(3px)",
  borderRadius: 2,
  overflowY: "auto",
};
type FormValues = {
  patient: number | null;
  blood_high: string;
  blood_low: string;
  pulse_rate: string;
  bio_func: string;
  spo: string;
  weight: string;
  in_charge: string;
  administered_on: string;
};

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const AddVital = ({ open, onClose }: AddEmpProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(
    null
  );
  const params = useParams();
  const navigate = useNavigate();
  const pTId = parseInt(params.id || "0");

  const { queryKey: vitals, queryFn: vitalsFunc } = queryConfigs.getVitals;
  const { data: vitalsData, isLoading: isVitalsLoading } = useGetQuery({
    key: vitals,
    func: vitalsFunc,
    params: { offset: 0, limit: 10, patient: pTId },
    isEnabled: params.id ? true : false,
  });

  const { queryKey: employees, queryFn: employeesFunc } =
    queryConfigs.useGetEmployees;
  const { data: employeeData, isLoading: isEmployeeLoaiding } = useGetQuery({
    key: employees,
    func: employeesFunc,
  });
  const [formValues, setFormValues] = useState<FormValues>({
    patient: null,
    blood_high: "",
    blood_low: "",
    pulse_rate: "",
    bio_func: "",
    spo: "",
    weight: "",
    in_charge: "",
    administered_on: "",
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };
  const queryClient = useQueryClient();
  const { queryFn, queryKey } = queryConfigs.useAddVitals;
  const { mutate } = useMutationQuery({
    key: queryKey,
    func: queryFn,
    onSuccess: () => {
      showNotification("success", "Daily Vital Records added successfully");
      onClose();
      handleClearForm();
    },
  });

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleClearForm = () => {
    setFormValues({
      patient: null,
      blood_high: "",
      blood_low: "",
      pulse_rate: "",
      bio_func: "",
      spo: "",
      weight: "",
      in_charge: "",
      administered_on: "",
    });
  };

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const validateForm = (values: FormValues): Record<string, string> => {
    const errors: Record<string, string> = {};

    if (
      !values.patient ||
      !Number.isInteger(values.patient) ||
      values.patient <= 0
    ) {
      errors.patient = "Patient ID is required and must be a positive integer.";
    }

    const inCharge = parseInt(values.in_charge, 10);
    if (isNaN(inCharge) || inCharge <= 0) {
      errors.in_charge =
        "In-charge ID is required and must be a positive integer.";
    }

    const weight = parseFloat(values.weight);
    if (isNaN(weight) || weight <= 0) {
      errors.weight = "Weight is required and must be a positive number.";
    }

    if (
      !values.administered_on ||
      isNaN(new Date(values.administered_on).getTime())
    ) {
      errors.administered_on = "Administered on must be a valid date.";
    }

    return errors;
  };

  const handleSubmit = () => {
    const updatedForm = {
      ...formValues,
      patient: pTId,
      in_charge: parseInt(formValues.in_charge, 10),
      weight: parseFloat(formValues.weight),
    };
    if (!updatedForm.patient) {
      showNotification("error", "Patient ID not Available");
    }

    if (updatedForm.weight === 0) {
      showNotification("error", "Weight is required");
    }
    if (updatedForm.administered_on === "") {
      showNotification("error", "Administered Date is required");
    }

    if (!updatedForm.in_charge || updatedForm.in_charge <= 0) {
      showNotification("error", "Incharge is required");
    }

    mutate(updatedForm);
  };

  const handleCancel = () => {
    onClose();
    handleClearForm();
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      setFormValues((prevState) => ({
        ...prevState,
        administered_on: dayjs(date).format(),
      }));
    }
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <div className="bg-[#1C1C1C] sticky top-0 z-10 flex justify-between items-center px-4">
          <DialogTitle
            sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
          >
            Add Vitals
          </DialogTitle>
          <IconButton onClick={onClose} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </div>
        <section className="space-y-5 p-5">
          <div className="flex items-center justify-center gap-4">
            <div style={{ zIndex: "999" }}>
              <DatePicker
                selected={selectedDate ? dayjs(selectedDate).toDate() : null}
                onChange={handleDateChange}
                dateFormat="dd, MMMM, yyyy"
                placeholderText="Vital Recorded Date"
                maxDate={dayjs().toDate()}
                className="px-6 py-4 w-[310px] h-12 border border-gray-200 rounded-3xl placeholder-gray-500"
                popperPlacement="bottom-start"
                wrapperClassName="w-full"
              />
            </div>
            <TextField
              name="blood_high"
              label="Systolic"
              value={formValues.blood_high}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                },
              }}
              InputProps={{
                inputProps: {
                  maxLength: 10,
                  pattern: "[0-9]*",
                },
                classes: {
                  root: "text-black",
                  input: "h-10 py-0",
                },
              }}
              inputProps={{
                className: "text-black font-Poppins",
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                },
              }}
              onInput={(e) => {
                const input = e.target as HTMLInputElement;
                input.value = input.value.replace(/[^0-9]/g, "");
              }}
            />
            <TextField
              name="blood_low"
              label="Diastolic"
              value={formValues.blood_low}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                },
              }}
              InputProps={{
                inputProps: {
                  maxLength: 10,
                  pattern: "[0-9]*",
                },
                classes: {
                  root: "text-black",
                  input: "h-10 py-0",
                },
              }}
              inputProps={{
                className: "text-black font-Poppins",
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                },
              }}
              onInput={(e) => {
                const input = e.target as HTMLInputElement;
                input.value = input.value.replace(/[^0-9]/g, "");
              }}
            />
          </div>
          <div className="flex items-center justify-center gap-4">
            <TextField
              name="pulse_rate"
              label="Pulse Rate"
              value={formValues.pulse_rate}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                },
              }}
              InputProps={{
                inputProps: {
                  maxLength: 10,
                  pattern: "[0-9]*",
                },
                classes: {
                  root: "text-black",
                  input: "h-10 py-0",
                },
              }}
              inputProps={{
                className: "text-black font-Poppins",
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                },
              }}
              onInput={(e) => {
                const input = e.target as HTMLInputElement;
                input.value = input.value.replace(/[^0-9]/g, "");
              }}
            />

            <TextField
              name="spo"
              label="SPO2"
              value={formValues.spo}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                },
              }}
              InputProps={{
                inputProps: {
                  maxLength: 10,
                  pattern: "[0-9]*",
                },
                classes: {
                  root: "text-black",
                  input: "h-10 py-0",
                },
              }}
              inputProps={{
                className: "text-black font-Poppins",
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                },
              }}
              onInput={(e) => {
                const input = e.target as HTMLInputElement;
                input.value = input.value.replace(/[^0-9]/g, "");
              }}
            />
            <TextField
              name="weight"
              label="Weight (KGs)"
              value={formValues.weight}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              variant="outlined"
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                },
              }}
              InputProps={{
                inputProps: {
                  maxLength: 10,
                  pattern: "[0-9]*",
                },
                classes: {
                  root: "text-black",
                  input: "h-10 py-0",
                },
              }}
              inputProps={{
                className: "text-black font-Poppins",
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                },
              }}
              onInput={(e) => {
                const input = e.target as HTMLInputElement;
                input.value = input.value.replace(/[^0-9]/g, "");
              }}
            />
          </div>
          <TextField
            name="bio_func"
            multiline
            maxRows={2}
            label="Biological Functions"
            value={formValues.bio_func}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: {
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              },
            }}
            InputProps={{
              classes: {
                root: "text-black",
                input: "h-8 py-0",
              },
            }}
            inputProps={{
              className: "text-black",
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "1.5rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "inherit",
              },
              "& .MuiInputBase-input": {
                height: "1rem",
                padding: "1rem 1rem",
                textTransform: "capitalize",
              },
            }}
          />
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel
              style={{
                color: "gray",
                fontFamily: "Poppins, sans-serif",
                fontSize: "13px",
              }}
            >
              Select In Charge
            </InputLabel>
            <Select
              name="in_charge"
              value={formValues.in_charge}
              onChange={handleSelectChange}
              label="Select In Charge"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                  borderRadius: "1.5rem",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                  textTransform: "capitalize",
                },
                "& .MuiSelect-icon": {
                  color: "gray",
                },
              }}
            >
              {employeeData?.list?.map((employee: EmpRow) => (
                <MenuItem key={employee.id} value={employee.id}>
                  {employee.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </section>
        <div className="flex justify-center mb-4 items-center">
          <DialogActions>
            <div className="mr-12">
              <button
                onClick={handleSubmit}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#4CAF50] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#4CAF50] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#4CAF50] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Add
                </span>
                <span className="absolute inset-0 border-2 border-[#4CAF50] rounded-lg"></span>
              </button>
            </div>
            <div className="ml-12">
              <button
                onClick={handleCancel}
                className="relative items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-lg group"
              >
                <span className="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-[#d00000] opacity-[3%]"></span>
                <span className="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[#d00000] opacity-100 group-hover:-translate-x-8"></span>
                <span className="relative w-full text-left text-[#d00000] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Cancel
                </span>
                <span className="absolute inset-0 border-2 border-[#d00000] rounded-lg"></span>
              </button>
            </div>
          </DialogActions>
        </div>
      </Box>
    </Modal>
  );
};

export default AddVital;
