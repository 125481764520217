import { useEffect, useRef, useState } from "react";
import { uploadFile } from "../../services/api";
import { showNotification } from "../../services/helper";
import { queryConfigs } from "../../hooks/config";
import { useGetQuery, useMutationQuery } from "../../hooks/queryHook";
import UpdateUserPass from "./UpdateUserPass";
import TextField from "@mui/material/TextField";
import { InputLabel, Typography } from "@mui/material";

const inputStyle = `
  h-[2.35rem] border border-gray-300 rounded px-3 outline-none
  focus:border-2 focus:border-[#666876] text-xs font-Poppins
`;
const labelStyle = `text-sm font-semibold font-Poppins text-[#666876]`;
const imageContainer = `
  custom-img-upload relative h-[200px] w-[200px]
  overflow-hidden rounded-full flex justify-center items-center border hover:border-cyan-500
`;
const imageContent = "bg-gradient-green mb-5 cursor-pointer rounded-full p-1";

type UserProfile = {
  name: string;
  mobile: string;
  email: string;
  image: string;
};

const Profile = () => {
  const { queryKey, queryFn } = queryConfigs.getProfile;
  const { mutationFn, queryKey: profileKey } = queryConfigs.updateProfile;
  const { mutate } = useMutationQuery({
    key: profileKey,
    func: mutationFn,
    onSuccess: () => {
      showNotification("success", "Profile updated successfully");
    },
  });
  const { data, refetch, isLoading } = useGetQuery({
    key: queryKey,
    func: queryFn,
  });
  const inputImageRef = useRef<HTMLInputElement>(null);
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [form, setForm] = useState<UserProfile>({
    name: "",
    mobile: "",
    email: "",
    image: "",
  });
  const [previewImage, setPreviewImage] = useState("");
  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    setCurrentFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
  };
  useEffect(() => {
    if (data?.data?.image) {
      setPreviewImage(data?.data?.image);
    } else {
      setPreviewImage("");
    }
  }, [data]);

  const submitHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      const body = { ...form };
      if (currentFile) {
        const formData = new FormData();
        formData.append("file", currentFile);
        const response = await uploadFile(formData);
        if (response?.data?.success && response?.data?.result) {
          body.image = response?.data?.result ?? "";
        }
      }
      mutate({
        image: body.image ?? "",
      });
    } catch (err) {
      console.error(err);
      showNotification("error", "Unable to update profile");
    }
  };

  const handleTargetOnChange = () => {
    if (!inputImageRef.current) return;
    inputImageRef.current?.click();
  };

  const handleUpdatePassword = () => {
    setOpenPwd(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const [openPwd, setOpenPwd] = useState(false);

  return (
    <>
      <div className="bg-gray-100 min-h-screen flex items-center justify-center">
        <div className="bg-white p-8 rounded shadow-md w-[500px] ">
          <div className="w-[92%] flex justify-center items-center">
            <div className="max-w-max">
              <div className="flex flex-col space-y-1">
                <div onClick={handleTargetOnChange} className={imageContent}>
                  <div className={imageContainer}>
                    {previewImage ? (
                      <img
                        className="h-[200px] w-[200px]"
                        src={`${process.env.FILE_URL_GET}${previewImage}` || ""}
                        alt="Preview"
                      />
                    ) : (
                      <span className="text-gray-500">Upload Image</span>
                    )}
                  </div>
                </div>
                <input
                  ref={inputImageRef}
                  type="file"
                  accept="image/*"
                  onChange={selectFile}
                  className={`hidden ${inputStyle}`}
                />
              </div>
            </div>
          </div>

          <h1 className="text-2xl font-bold text-gray-800 text-center">
            {data?.data?.name}
          </h1>

          <form className="mt-4 space-y-4 p">
            <InputLabel
              style={{ color: "#000" }}
              sx={{
                paddingLeft: 1,
              }}
            >
              Email
            </InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              name="email"
              value={data?.data?.email}
              onChange={handleChange}
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                },
              }}
              InputProps={{
                classes: {
                  root: "text-black",
                  input: "h-10 py-0",
                },
              }}
              inputProps={{
                className: "text-black",
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                },
              }}
              className="rounded-3xl bg-white"
            />
            <InputLabel
              style={{ color: "#000" }}
              sx={{
                paddingLeft: 1,
              }}
            >
              Mobile
            </InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              name="mobile"
              value={data?.data?.mobile}
              onChange={handleChange}
              InputLabelProps={{
                style: {
                  color: "gray",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "13px",
                },
              }}
              InputProps={{
                classes: {
                  root: "text-black",
                  input: "h-10 py-0",
                },
              }}
              inputProps={{
                className: "text-black",
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                },
              }}
              className="rounded-3xl bg-white"
            />
            <InputLabel
              style={{ color: "#000" }}
              sx={{
                paddingLeft: 1,
              }}
            >
              Role
            </InputLabel>
            <Typography
              sx={{
                ml: 1,
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "1.5rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit",
                },
                "& .MuiInputBase-input": {
                  height: "1rem",
                  padding: "1rem 1rem",
                },
              }}
              className="rounded-3xl bg-white"
            >
              {data?.data?.role === 1 ? "STAFF" : "ADMIN"}
            </Typography>
            <div className="py-8 flex justify-between px-6">
              {currentFile && (
                <button className="select-btn" onClick={submitHandler}>
                  Update Profile
                </button>
              )}
            </div>
          </form>
          <button className="select-btn" onClick={handleUpdatePassword}>
            Update Password
          </button>
        </div>
      </div>
      <UpdateUserPass open={openPwd} onClose={() => setOpenPwd(false)} />
    </>
  );
};

export default Profile;
